// jshint ignore: start
import React from "react"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import Typography from "@material-ui/core/Typography"
import { handleLogin, isLoggedIn } from "../services/auth"
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import getFirebase from './firebase';
import SEO from "../components/seo"

const firebase = getFirebase();

class Login extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        isSignedIn: false,
        username: '',
        password: '',
        displayName: '',
        avatar: '',
        uiConfig: ''
      }
  }

  componentDidMount = () => {
    if (!firebase) return;

    firebase.auth().onAuthStateChanged(user => {
      this.setState({
        isSignedIn: !!user
      })
      if (user) {
        this.setState({
          displayName: user.displayName,
          username: user.email,
          avatar: user.photoURL
        })
        handleLogin(this.state)
      }
    })
  }

  componentDidUpdate = () => {
    if (!this.state.uiConfig && firebase) {
      this.setState({
        uiConfig: {
          signInFlow: "popup",
          signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID
          ],
          callbacks: {
            signInSuccessWithAuthResult: () => false
          }
        }
      })
    }
  }

  render() {
     const { intl } = this.props
    if (isLoggedIn()) {
      navigate(`/app/account`)
    }

    return (
      <div style={{minHeight: 375}}>
        <SEO title={intl.formatMessage({id: "sign.seo"})} />
        <div style={{textAlign: 'center', width: '100%', marginLeft: 'auto', marginRight: 'auto', padding: '40px 0'}}>
        <Typography component="h1" variant="h4">Acroleads</Typography>
        <Typography component="h2" variant="body1">{intl.formatMessage({id: "sign.welcome"})}</Typography>
        {this.state.isSignedIn ? (
          navigate(`/app/account`)
        ): (this.state.uiConfig.signInFlow) ? (
          <StyledFirebaseAuth
            uiConfig={this.state.uiConfig}
            firebaseAuth={firebase.auth()}
          />
        ) : null}
        </div>
      </div>
    )
  }
}

export default injectIntl(Login)