// jshint ignore: start
import React, { useState, useRef, useEffect } from 'react';
import styles from './index.module.css';
import CallToActionIcon from '@material-ui/icons/CallToActionOutlined'
import Tooltip from '@material-ui/core/Tooltip';

export default function CallToAction(props) {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  var node = useRef(null);
  var node2 = useRef(null);

  const handleClick = () => {
    setOpen(prev => !prev);
  };

  const handleClickOutside = (e) => {
    if (node.contains && node.contains(e.target) || node2.contains && node2.contains(e.target)) {
      return
    }
    if (open) {
      setOpen(false)
    }
  }

  const changeColor = (evt) => {
        const { editorState, onChange } = props;
        onChange(props.modifier(editorState, title, link, evt.target.id));
        setTitle("")
        setLink("")
        setOpen(false)
  }

  const changeTitle = (evt) => {
    setTitle(evt.target.value)
  }

  const changeLink = (evt) => {
    setLink(evt.target.value)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const buttonClassName = open ? styles.addActionPressedButton : styles.addActionButton;
  const colors = ['000000','333333','666666','999999','cccccc','ffffff','61a951','16a085','07a9fe','003ba5','8e44ad','f32784','c0392b','d35400','f39c12','fdda00']
  const myColor = colors.map(color => {return <li onClick={changeColor} id={color} key={color} style={{ color: "#" + color, boxShadow: color === 'ffffff' ? '0 0 0 1px #ccc' : 'none'}}></li>})

  return (
    <div className={styles.addAction}>
    <Tooltip title="Add Button">
      <button
        ref={(element) => { node2 = element; }}
        className={buttonClassName}
        onClick={handleClick}
        type="button"
      >
      <CallToActionIcon/>
      </button>
      </Tooltip>
      {open &&
        <div
          ref={(element) => { node = element; }}
          className={styles.addActionPopover}
        >
        <div style={{padding: '0 15px'}}>
          <input
            type="text"
            placeholder="Paste the Title …"
            className={styles.addActionInput}
            onChange={changeTitle}
            value={title}
          />
          <input
            type="text"
            placeholder="Paste the Link …"
            className={styles.addActionInput}
            onChange={changeLink}
            value={link}
          />
        </div>
          <ul className={styles.addColors}>
            {myColor}
          </ul>
        </div>
      }      
    </div>
  )
}