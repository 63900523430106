// jshint ignore: start
import React, {useForwardedRed} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const BootstrapButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 15,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#007bff',
    borderColor: '#007bff',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: '#0069d9',
      borderColor: '#0062cc',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf',
    },
    // '&:focus': {
    //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    // },
  },
  disabled: {
    transition: 'none',
    borderColor: '1px solid rgba(0, 0, 0, 0.26)',
    border: '1px solid rgba(0, 0, 0, 0.26)',
    '&:hover': {
      borderColor: '1px solid rgba(0, 0, 0, 0.26)',
      border: '1px solid rgba(0, 0, 0, 0.26)',
    }
  }
})(Button);

const MyButton = (props) => {
    return <BootstrapButton variant="contained" color="primary" disableRipple {...props} />;
}

export default MyButton;

