// jshint ignore: start
import React, { Component, useState, useCallback, useMemo } from "react"
import { withStyles } from "@material-ui/core/styles"
import Drawer from '@material-ui/core/Drawer'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import Close from '@material-ui/icons/Close'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { connect } from "react-redux";
import { addBulkContact, clearBulkContact } from "../../state/store/actions/contacts";
import { bindActionCreators } from 'redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import {useDropzone} from 'react-dropzone';
import XLSX from 'xlsx';
import * as EmailValidator from 'email-validator';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';

const styles = ( theme ) => ({
  loading: {
    visibility: 'visible'
  },
  completed: {
    visibility: 'hidden'
  },
  list: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    wordWrap: 'break-word',
    height: '100%'
  },
  titulo: {
    flex: 1,
    color: 'white'
  },
  close: {
    marginLeft: 'auto',
    color: 'white',
    boxShadow: 'none',
    background: 'none',
    '&:hover, &focusVisible': {
      background: 'rgb(0,0,0,0.25)'
    },
    '&:active, &focusVisible': {
      background: 'rgb(0,0,0,0.1)',
      boxShadow: 'none'
    }
  },
  dropcontent: {
      flex: 'none',
      padding: '30px 40px',
      flex: '1 1 0%', minHeight: 'auto',
      overflow: 'auto',
      flexGrow: 1
  },
  label: {
    padding: '9px 0'
  }
});

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 3,
  borderColor: "#b1b1b1",
  borderStyle: "dashed",
  backgroundColor: "#f7f7f7",
  color: "#666666",
  outline: "none",
  transition: "border .24s ease-in-out",
}

const activeStyle = {
  borderColor: "#2196f3",
}

const acceptStyle = {
  borderColor: "#00e676",
}

const rejectStyle = {
  borderColor: "#ff1744",
}

 const make_cols = refstr => {
    let o = [],
        C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = {
        name: XLSX.utils.encode_col(i),
        key: i
    }
    return o;
};

function readUTF8String(bytes) {
  var ix = 0;

  if (bytes.slice(0, 3) == "\xEF\xBB\xBF") {
    ix = 3;
  }

  var string = "";
  for (; ix < bytes.length; ix++) {
    var byte1 = bytes[ix].charCodeAt(0);
    if (byte1 < 0x80) {
      string += String.fromCharCode(byte1);
    } else if (byte1 >= 0xC2 && byte1 < 0xE0) {
      var byte2 = bytes[++ix].charCodeAt(0);
      string += String.fromCharCode(((byte1 & 0x1F) << 6) + (byte2 & 0x3F));
    } else if (byte1 >= 0xE0 && byte1 < 0xF0) {
      var byte2 = bytes[++ix].charCodeAt(0);
      var byte3 = bytes[++ix].charCodeAt(0);
      string += String.fromCharCode(((byte1 & 0xFF) << 12) + ((byte2 & 0x3F) << 6) + (byte3 & 0x3F));
    } else if (byte1 >= 0xF0 && byte1 < 0xF5) {
      var byte2 = bytes[++ix].charCodeAt(0);
      var byte3 = bytes[++ix].charCodeAt(0);
      var byte4 = bytes[++ix].charCodeAt(0);
      var codepoint = ((byte1 & 0x07) << 18) + ((byte2 & 0x3F) << 12) + ((byte3 & 0x3F) << 6) + (byte4 & 0x3F);
      codepoint -= 0x10000;
      string += String.fromCharCode(
        (codepoint >> 10) + 0xD800, (codepoint & 0x3FF) + 0xDC00
      );
    }
  }

  return string;
}

function Dropzone(props) {
    const [data, setData] = useState([]);
    const [cols, setCols] = useState([]);
    const [filepath, setPath] = useState('');
    const [filesize, setSize] = useState('');
    const [total, setTotal] = useState(0);
    const [invalid, setInvalid] = useState(0);
    const [valid, setValid] = useState(0);
    const [checked, setCheck] = useState(false);
    const intl = props.intl;
    const [importObj, setImportObj] = useState({});

    const onDrop = useCallback(acceptedFiles => {
        const reader = new FileReader()
        const rABS = !!reader.readAsBinaryString;

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
            const binaryStr = reader.result
            const wb = XLSX.read(readUTF8String(binaryStr), { type: rABS ? 'binary' : 'array', bookVBA : true });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);

            setData(data)
            setCols(make_cols(ws['!ref']))

            const myobj = JSON.stringify(data, null, 2)

            // console.log(myobj)
            // console.log(data.length)
            var inv = 0

            var finaldata = []
            data.map((contact) => {

                const firstname = (contact['First Name'] != undefined) ? contact['First Name'] : ""
                const lastname = (contact['Last Name'] != undefined) ? contact['Last Name'] : ""
                const email = (contact['Email Address'] != undefined) ? contact['Email Address'] : ""
                const phone = (contact['Phone Number'] != undefined) ? contact['Phone Number'] : ""
                const status = (contact['Status'] != undefined) ? contact['Status'] : ""

                if ((EmailValidator.validate(email)) || ((!EmailValidator.validate(email)) && ((firstname + ' ' + lastname).trim().length != 0)) && email.length == 0) {
                    finaldata.push({
                        'firstname': firstname,
                        'lastname': lastname,
                        'email': email,
                        'phone': phone,
                        'status': (status.toLowerCase() === "enabled" || status.toLowerCase() === "disabled") ? status.toLowerCase() : "enabled",
                        'lang': 'en'
                    })
                } else {
                    inv = inv + 1
                }

            })

            var sl = finaldata;
            var out = [];
            for (var i = 0, l = sl.length; i < l; i++) {
              var unique = true;
              for (var j = 0, k = out.length; j < k; j++) {
                if ((sl[i].email === out[j].email) || (sl[i].email === "")) {
                  unique = false;
                }
              }
              if (unique) {
                out.push(sl[i]);
              }
              if (sl[i].email === "") {
                out.push(sl[i])
              }
            }

            setTotal(data.length)
            setInvalid(inv)
            setValid(out.length - inv)
            setImportObj(out)
        }

        acceptedFiles.forEach(file => {
            if (rABS) {
                reader.readAsBinaryString(file);
            } else {
                reader.readAsArrayBuffer(file);
            };
            setPath(file.path)
            setSize(file.size)
        })

    }, [])

    const {getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open } = useDropzone({
        accept: 'text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        noClick: false,
        noKeyboard: true,
        multiple: false,
        onDrop
    });

    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isDragActive, isDragReject]
    )

  const handleChange = name => event => {
    setCheck(event.target.checked)
    props.handleUpdateFile(valid == 0 || (valid != 0 && !event.target.checked))
    props.handleImportObject(importObj)
  };

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <Button
          variant="outlined"
          color="secondary"
          style={{ margin: "14px 0" }}
        >
          {intl.formatMessage({ id: "cont.uploadfile" })}
        </Button>
        <input {...getInputProps()} />
        <p>{intl.formatMessage({ id: "cont.ordrag" })}</p>
        <p>{intl.formatMessage({ id: "cont.only" })}</p>
      </div>
      <p>
        {intl.formatMessage({ id: "cont.downloadfile" })}
        <br />
        <a
          style={{ textDecoration: "none" }}
          href="https://d1vhh2lpbtr7th.cloudfront.net/samples/Leads_XLSX.zip"
        >
          <small>
            {intl.formatMessage({ id: "cont.fileexample" })} - Download.xlsx
          </small>
        </a>
        <br />
        <a
          style={{ textDecoration: "none" }}
          href="https://d1vhh2lpbtr7th.cloudfront.net/samples/Leads_CSV.zip"
        >
          <small>
            {intl.formatMessage({ id: "cont.fileexample" })} - Download.csv
          </small>
        </a>
      </p>
      <aside>
        {filepath ? (
          <div>
            <Divider />
            <p>
              {filepath} - {filesize} bytes
            </p>
            <p
              style={{
                fontWeight: 700,
              }}
            >
              {invalid}
              {" - "}
              {intl.formatMessage({ id: "cont.validreg" })}
            </p>
            <p
              style={{
                color: "#0acc50",
                fontWeight: 700,
              }}
            >
              {valid}
              {" - "}
              {intl.formatMessage({ id: "cont.validcontacts" })}
            </p>
            <FormControlLabel
              style={{ alignItems: "start" }}
              classes={{ label: props.myclasslabel }}
              control={
                <Checkbox
                  disabled={valid == 0}
                  checked={checked}
                  onChange={handleChange("checked")}
                  value="checked"
                />
              }
              label={intl.formatMessage({ id: "cont.iagree" })}
            />
          </div>
        ) : null}
      </aside>
    </div>
  )
}

 class rightImportDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
        save: false,
        disablecreate: true,
        importObject: {}
    }
    this.sideList = this.sideList.bind(this)
    this.toggleDrawerImport = this.toggleDrawerImport.bind(this)
    this.toggleAdd = this.toggleAdd.bind(this)
    this.handleUpdateFile = this.handleUpdateFile.bind(this)
    this.handleImportObject = this.handleImportObject.bind(this)
  }

  toggleDrawerImport = (open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.props.sendopenimport(open, open)
  };

  toggleAdd = () => event => {
    this.setState({save: true})
    this.props.clearBulkContact()
    this.props.addBulkContact(this.state.importObject)
  };

  componentDidUpdate(prevProps) {
    if (prevProps.bulkcontact !== this.props.bulkcontact) {
        if (this.props.bulkcontact === "success") {
            this.setState({save: false})
            this.setState({disablecreate: true})
            this.props.sendopenimport(false, true)
        }
    }
  }

  handleUpdateFile = (update) => {
     this.setState({disablecreate: update})
  }

  handleImportObject = (importobj) => {
    this.setState({importObject: importobj})
  }

  sideList = () => (
    <div className={this.props.classes.list}>
        <Toolbar style={{height: 64, backgroundColor: '#08a742'}}>
            <h2 className={this.props.classes.titulo}>{this.props.intl.formatMessage({id: "cont.importcontacts"})}</h2>
            <Fab disableRipple size="small" elevation={0} aria-label="close" className={this.props.classes.close} onClick={this.toggleDrawerImport(false)}>
            <Close/>
            </Fab>
        </Toolbar>
        <LinearProgress style={this.state.save ? {visibility: 'visible'} : {visibility: 'hidden'}} color="secondary" />
        <div className={this.props.classes.dropcontent}>
            <Dropzone
                intl={this.props.intl}
                myclasslabel={this.props.classes.label}
                handleImportObject={this.handleImportObject.bind(this)}
                handleUpdateFile={this.handleUpdateFile.bind(this)}/>
        </div>
        <Toolbar style={{width: '100%', backgroundColor: '#f5f8fa',
    borderTop: '1px solid #cbd6e2', paddingBottom: 20, paddingTop: 20}}>
          <ButtonGroup
            fullWidth
            color="secondary"
            size="large"
            aria-label="large full width outlined secondary button group">
            <Button disabled={this.state.disablecreate || this.state.save} onClick={this.toggleAdd()} style={{backgorund: '#ffe5e5'}}>{this.props.intl.formatMessage({id: "cont.importcontacts"})}</Button>
            <Button onClick={this.toggleDrawerImport(false)}>{this.props.intl.formatMessage({id: "cont.cancel"})}</Button>
          </ButtonGroup>
        </Toolbar>
    </div>
  );

  render() {
    return (
      <div>
        <Drawer anchor="right" open={this.props.open} onClose={this.toggleDrawerImport(false)}>
          {this.sideList()}
        </Drawer>
      </div>
    )
  }
};

function mapStateToProps(state) {
  return {
    bulkcontact: state.contacts.bulkcontact,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    addBulkContact,
    clearBulkContact
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(rightImportDrawer));