// jshint ignore: start
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import Close from '@material-ui/icons/Close'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { connect } from "react-redux";
import { newContact, updateContacts, emailContacts, clearContact } from "../../state/store/actions/contacts";
import { bindActionCreators } from 'redux';
import * as EmailValidator from 'email-validator';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import MyInput from '../../tools/MyInput'
import languages from "@cospired/i18n-iso-languages"

languages.registerLocale(require("@cospired/i18n-iso-languages/langs/en.json"))
languages.registerLocale(require("@cospired/i18n-iso-languages/langs/pt.json"))

const styles = ( theme ) => ({
  loading: {
    visibility: 'visible'
  },
  completed: {
    visibility: 'hidden'
  },
  list: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    wordWrap: 'break-word',
    height: '100%'
  },
  titulo: {
    flex: 1,
    color: 'white'
  },
  close: {
    marginLeft: 'auto',
    color: 'white',
    boxShadow: 'none',
    background: 'none',
    '&:hover, &focusVisible': {
      background: 'rgb(0,0,0,0.25)'
    },
    '&:active, &focusVisible': {
      background: 'rgb(0,0,0,0.1)',
      boxShadow: 'none'
    }
  },
});

 class rightDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      status: '',
      lang: '',
      disablecreate: true,
      disableemail: true,
      save: false,
      editing: false,
      emailexist: false
    }
    this.sideList = this.sideList.bind(this)
    this.toggleDrawer = this.toggleDrawer.bind(this)
    this.toggleAdd = this.toggleAdd.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  toggleDrawer = (open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({emailexist: null})
    this.props.sendopen(open, open)
  };

  toggleAdd = () => event => {
    this.props.newContact(this.state.firstname, this.state.lastname, this.state.email, "")
    this.setState({save: true})
  };

  toggleUpdate = () => event => {
    const docid = this.props.old['refid']
    const updateObj = {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
      phone: this.state.phone,
      status: this.state.status,
      lang: this.state.lang
    }
    this.props.updateContacts(docid, updateObj)
    this.setState({save: true})
  };

  componentDidUpdate(prevProps) {
    if (prevProps.emailcontact !== this.props.emailcontact) {
      this.setState({ emailexist: this.props.emailcontact}, () => {
        if (EmailValidator.validate(this.state.email)) {
          if (this.state.emailexist === "emailtrue") {
            this.setState({ disablecreate: true })
            this.setState({ disableemail: true })
          } else if (this.state.emailexist === "emailfalse") {
            this.setState({ disablecreate: false })
            this.setState({ disableemail: false })
          }
        } else if (this.state.email.length) {
          this.setState({ disablecreate: true })
          this.setState({ disableemail: true })
        }
      })
    }

    if (prevProps.contact !== this.props.contact) {
      if (typeof this.props.contact != undefined) {
        this.setState({save: false})
        this.setState({firstname: ''})
        this.setState({lastname: ''})
        this.setState({email: ''})
        this.setState({disablecreate: true})
        this.props.sendopen(false, true)
      }
    }
    if (prevProps.old !== this.props.old) {
      if (Object.keys(this.props.old).length) {
        this.setState({editing: true}, () => {
          this.setState({firstname: this.props.old['firstname']})
          this.setState({lastname: this.props.old['lastname']})
          this.setState({email: this.props.old['email']})
          this.setState({phone: this.props.old['phone']})
          this.setState({status: this.props.old['status']})
          this.setState({lang: this.props.old['lang']})
        })
      } else {
        this.setState({firstname: ''})
        this.setState({lastname: ''})
        this.setState({email: ''})
        this.setState({phone: ''})
        this.setState({status: ''})
        this.setState({lang: ''})
        this.setState({editing: false})
      }
    }
  }

  handleChange(event) {
    const {name, id, value} = event.target
    this.setState({[name ? name : id]: value}, () => {
      const fn = this.state.firstname.length ? this.state.firstname.length : 0
      const ln = this.state.lastname.length ? this.state.lastname.length : 0
      const em = this.state.email.length ? this.state.email.length : 0
      if ((fn + ln) > 0 && em < 1) {
        this.setState({disablecreate: false})
      } else {
        this.setState({disablecreate: true})
      }
      this.setState({emailexist: true}, () => {
        this.props.clearContact()
        const refid = this.props.old['refid'] ? this.props.old['refid'] : ""
        this.props.emailContacts(refid, this.state.email)
      })
    });
  };
  sideList = () => (
    <div className={this.props.classes.list}>
      <Toolbar style={{height: 64, backgroundColor: this.state.editing ? '#e5b403' : '#039be5'}}>
        <h2 className={this.props.classes.titulo}>{this.state.editing ? this.props.intl.formatMessage({id: "cont.change"}) : this.props.intl.formatMessage({id: "cont.new"})}</h2>
        <Fab disableRipple size="small" elevation={0} aria-label="close" className={this.props.classes.close} onClick={this.toggleDrawer(false)}>
          <Close/>
        </Fab>
      </Toolbar>
      <LinearProgress style={this.state.save ? {visibility: 'visible'} : {visibility: 'hidden'}} color="secondary" />
      <div style={{flex: 'none', padding: '30px 40px', flex: '1 1 0%', minHeight: 'auto', overflow: 'auto', flexGrow: 1}}>
        <FormControl style={{width: '100%', marginBottom: 20}} className={this.props.classes.margin}>
          <InputLabel shrink htmlFor="newcontact-input1">
            {this.props.intl.formatMessage({id: "cont.firstname"})}
          </InputLabel>
          <MyInput disabled={this.state.save} onChange={this.handleChange} style={{width: '100%'}} value={this.state.firstname} id="firstname" />
        </FormControl>
        <FormControl style={{width: '100%',  marginBottom: 20}} className={this.props.classes.margin}>
          <InputLabel shrink htmlFor="newcontact-input2">
            {this.props.intl.formatMessage({id: "cont.lastname"})}
          </InputLabel>
          <MyInput disabled={this.state.save} onChange={this.handleChange} style={{width: '100%'}} value={this.state.lastname} id="lastname" />
        </FormControl>
        <FormControl style={{width: '100%',  marginBottom: 20}} className={this.props.classes.margin}>
          <InputLabel shrink htmlFor="newcontact-input3">
            {this.props.intl.formatMessage({id: "cont.email"})}
          </InputLabel>
          <MyInput disabled={this.state.save} onChange={this.handleChange} style={{width: '100%'}} value={this.state.email} id="email" />
        </FormControl>
        {this.state.emailexist === "emailtrue" ? <div style={{textAlign: 'center', color: 'red', marginBottom: 20}}>{this.props.intl.formatMessage({id: "cont.same"})}{' '}<strong>{this.state.email}</strong></div> : null}
        {this.state.editing ? (
          <React.Fragment>
            <FormControl style={{width: '100%',  marginBottom: 20}} className={this.props.classes.margin}>
              <InputLabel shrink htmlFor="newcontact-input4">
                {this.props.intl.formatMessage({id: "cont.phone"})}
              </InputLabel>
              <MyInput disabled={this.state.save} onChange={this.handleChange} style={{width: '100%'}} value={this.state.phone} id="phone" />
            </FormControl>
            <FormControl style={{width: '100%',  marginBottom: 20}} className={this.props.classes.margin}>
              <InputLabel shrink htmlFor="newcontact-input5">
                {this.props.intl.formatMessage({id: "cont.status"})}
              </InputLabel>
              <Select
                disabled={this.state.save}
                value={this.state.status}
                onChange={this.handleChange}
                input={<MyInput name="status" />}
              >
                <MenuItem value={'enabled'}>{this.props.intl.formatMessage({id: "cont.enabled"})}</MenuItem>
                <MenuItem value={'disabled'}>{this.props.intl.formatMessage({id: "cont.disabled"})}</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{width: '100%',  marginBottom: 20}} className={this.props.classes.margin}>
              <InputLabel shrink htmlFor="newcontact-input6">
                Linguagem
              </InputLabel>
              <Select
                disabled={this.state.save}
                value={this.state.lang}
                onChange={this.handleChange}
                input={<MyInput name="lang" />}
              >
                <MenuItem value={'en'}>{languages.getName("en", this.props.intl.locale)}</MenuItem>
                <MenuItem value={'pt'}>{languages.getName("pt", this.props.intl.locale)}</MenuItem>
              </Select>
            </FormControl>
          </React.Fragment>
        ) : (null) }
        </div>
        <Toolbar style={{width: '100%', backgroundColor: '#f5f8fa', borderTop: '1px solid #cbd6e2', paddingBottom: 20, paddingTop: 20}}>
          <ButtonGroup
            fullWidth
            color="secondary"
            size="large"
            aria-label="large full width outlined secondary button group">
            <Button disabled={this.state.disablecreate || this.state.save} onClick={this.state.editing ? this.toggleUpdate(true) : this.toggleAdd(true)} style={{backgorund: '#ffe5e5'}}>{this.state.editing ? this.props.intl.formatMessage({id: "cont.edit"}) : this.props.intl.formatMessage({id: "cont.createcontact"})}</Button>
            <Button onClick={this.toggleDrawer(false)}>{this.props.intl.formatMessage({id: "cont.cancel"})}</Button>
          </ButtonGroup>
        </Toolbar>
    </div>
  );

  render() {
    return (
      <div>
        <Drawer anchor="right" open={this.props.open} onClose={this.toggleDrawer(false)}>
          {this.sideList()}
        </Drawer>
      </div>
    )
  }
};

function mapStateToProps(state) {
  return {
    contact: state.contacts.contact,
    emailcontact: state.contacts.emailcontact
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    newContact,
    updateContacts,
    emailContacts,
    clearContact
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(rightDrawer));