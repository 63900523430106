// jshint ignore: start
import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import Close from '@material-ui/icons/Close'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import LinearProgress from '@material-ui/core/LinearProgress';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import FormControl from '@material-ui/core/FormControl'
import MyInput from '../tools/MyInput'
import MyButton from '../tools/MyButton'
import { useSelector, useDispatch } from "react-redux";
import { requestEmailValidation, requestNewDomain } from "../state/store/actions/domainsettings";
import * as EmailValidator from 'email-validator';
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { navigate } from 'gatsby-plugin-intl'

const useStyles = makeStyles({
  loading: {
    visibility: 'visible'
  },
  completed: {
    visibility: 'hidden'
  },
  list: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    wordWrap: 'break-word',
    height: '100%'
  },
  titulo: {
    flex: 1,
    color: 'white'
  },
  close: {
    marginLeft: 'auto',
    color: 'white',
    boxShadow: 'none',
    background: 'none',
    '&:hover, &focusVisible': {
      background: 'rgb(0,0,0,0.25)'
    },
    '&:active, &focusVisible': {
      background: 'rgb(0,0,0,0.1)',
      boxShadow: 'none'
    }
  },
  dns: {
    margin: '10px -10px 10px -30px',
    border: '2px solid rgb(3, 155, 229)',
    borderRadius: 4,
    padding: 6
    
  }
});

function getSteps() {
    return ['Escolher Domínio', 'Verificar Domínio', 'Configuração DNS'];
}

function getStepContent(step, email, handleInput, handleChange, checked, save, verified, wrongcode, token, dkims, domain) {
    const classes = useStyles();
    switch (step) {
        case 0:
            return (
                <React.Fragment>
                    <Typography style={{marginBottom: 10}}>Verifique um domínio para usar como remetente ao enviar e-mails</Typography>
                    <FormControl style={{width: '100%', marginBottom: 20}} className={classes.margin}>
                        <MyInput style={{width: '100%'}} disabled={save} onChange={handleInput} value={email} placeholder="nome@dominio.com" id="email" />
                    </FormControl>
                </React.Fragment>
            )
        case 1:
            return (
                <React.Fragment>
                    <Typography style={{marginBottom: 10}}>{'Clique no link que recebeu no seu email '}<strong>{email}</strong>{' e de seguida clique abaixo em verificar'}</Typography>
                    <FormControl style={{width: '100%', marginBottom: 20, display: 'inline-block'}} className={classes.margin}>
                        { verified &&
                            <span style={{display: 'inline-flex', color: 'green', fontWeight: 800,verticalAlign: 'middle', marginTop: -4}} aria-label="delete">
                                Verificado
                            </span>
                        }
                        { !verified && wrongcode &&
                            <span style={{display: 'inline-flex', color: 'red', fontWeight: 800,verticalAlign: 'middle', marginTop: -4}} aria-label="delete">
                                Não verificado, Já clicou no link?
                            </span>
                        }
                    </FormControl>
                </React.Fragment>
            )
        case 2:
            return (
                <div>
                <span>Adicione os 4 registros abaixo ao seu DNS, se tiver dificuldade com este último passo contate o gestor do seu domínio ou solicite ajuda em support@acroleads.com</span>
                <div className={classes.dns}>
                    <div><strong>Tipo: </strong><span style={{color: '#f50057'}}>TXT</span></div>
                    <div><strong>Host: </strong><br></br>_amazonses.{domain}</div>
                    <div><strong>Valor: </strong>{token}</div>
                </div>
                <div className={classes.dns}>
                    <div><strong>Tipo: </strong><span style={{color: '#f50057'}}>CNAME</span></div>
                    <div><strong>Host: </strong>{dkims[0]}._domainkey.{domain}</div>
                    <div><strong>Valor: </strong>{dkims[0]}.dkim.amazonses.com.</div>
                </div>
                <div className={classes.dns}>
                    <div><strong>Tipo: </strong><span style={{color: '#f50057'}}>CNAME</span></div>
                    <div><strong>Host: </strong>{dkims[1]}._domainkey.{domain}</div>
                    <div><strong>Valor: </strong>{dkims[1]}.dkim.amazonses.com.</div>
                </div>
                <div className={classes.dns}>
                    <div><strong>Tipo: </strong><span style={{color: '#f50057'}}>CNAME</span></div>
                    <div><strong>Host: </strong>{dkims[2]}._domainkey.{domain}</div>
                    <div><strong>Valor: </strong>{dkims[2]}.dkim.amazonses.com.</div>
                </div>
                <FormControl>
                    <FormControlLabel
                        control={
                        <Checkbox checked={checked} onChange={handleChange} value="checked" />
                        }
                        label={'Confirmo que já coloquei os 4 registros nas definições de DSN no meu domínio ' + domain}
                    />
                </FormControl>
                </div>
            );
        default:
            return 'Unknown step';
    }
}

export default function ConnectDomain(props) {
  const classes = useStyles();
  const intl = props.intl;
  const [save, setSave] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [email, setEmail] = useState("");
  const [disableemail, setDisableemail] = useState(true);
  const [verified, setVerified] = useState(false);
  const [wrongcode, setWrongcode] = useState(false);
  const [token, setToken] = useState("")
  const [dkims, setDkims] = useState([])
  const [domain, setDomain] = useState("")
  const [checked, setChecked] = useState(false);
  const steps = getSteps();

  const domainsettings = useSelector(state => state.domainsettings);
  const dispatch = useDispatch();

  const handleChange = () => {
    setChecked(!checked);
  };

  function handleNext() {
    setSave(true)
    setVerified(false)
    setWrongcode(false)
    authUser().then((user) => {
        if (user) {
            const query = {
                uid: user.uid,
                email: email
            }
            firebase.functions().httpsCallable('validateEmail')({query}).then(result => {
                if (result.data) {
                    if (result.data.code === 400) {
                        setActiveStep(prevActiveStep => prevActiveStep + 1);
                        setSave(false)
                    } else if (result.data.code === 402) {
                        setSave(false)
                    }
                }
            });
        }
    })
  }

  function handleNext2() {
      setSave(true)
      authUser().then((user) => {
          if (user) {
              const query = {
                  uid: user.uid,
                  email: email
              }
              firebase.functions().httpsCallable('generateDNS')({
                  query
              }).then(result => {
                  if (result.data) {
                      const { code, token, DkimTokens, domain } = result.data
                      if (code === 400) {
                          const {} = result.data
                          setToken(token)
                          setDkims(DkimTokens)
                          setDomain(domain)
                          setActiveStep(prevActiveStep => prevActiveStep + 1);
                          setSave(false)
                      } else if (code === 402) {
                          setSave(false)
                      }
                  }
              });
          }
      })
  }

  function handleNext3() {
    setSave(true)
        authUser().then((user) => {
            if (user) {
                const query = {
                    uid: user.uid,
                    email: email
                }
                firebase.functions().httpsCallable('validateDomain')({
                    query
                }).then(result => {
                    if (result.data) {
                        setSave(false)
                    }
                });
            }
        })
  }

  function handleBack() {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  function handleReset() {
      setActiveStep(0);
  }

  const toggleDrawer = (open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setEmail("")
    setActiveStep(0);
    setChecked(false)
    props.sendopendomain(open, open)
  };

  const toggleAdd = () => event => {
    dispatch(requestNewDomain(email, domain, token, dkims));
    setSave(true)

  };

  const handleInput = (e) => {
    const { id, value} = e.target
    setEmail(value)
  }

  const handleEmailValidate = () => {
    setSave(true)
    setWrongcode(false)
    dispatch(requestEmailValidation(email))
  }

  function authUser() {
      return new Promise(function (resolve, reject) {
          firebase.auth().onAuthStateChanged(function (user) {
              if (user) {
                  resolve(user);
              } else {
                    reject('User not logged in');
                    var gu = localStorage.getItem("gatsbyUser");
                    if (gu) {
                        localStorage.setItem("gatsbyUser", JSON.stringify({}));
                    }
                    navigate("/app/login")
              }
          });
      });
  }

  function usePrevious(value) {
      const ref = useRef();
      useEffect(() => {
          if (!ref.current) {
              ref.current = true;
              authUser().then((user) => {
                  //
              }, (e) => {
                  console.log(e);
              });
          }
          ref.current = value
      }, [value]);
      return ref.current;
  }


  const prevValue = usePrevious({ domainsettings, email });
  useEffect(() => {
      if (prevValue) {
        if (EmailValidator.validate(email)) {
            setDisableemail(false)
        } else {
            setDisableemail(true)
        }
        if (prevValue.domainsettings !== domainsettings) {
            setSave(false)
            if (domainsettings.valemail) {
                if (prevValue.domainsettings.valemail !== domainsettings.valemail) {
                    if (domainsettings.valemail.data.VerificationAttributes[email].VerificationStatus === "Success") {
                        setVerified(true)
                        setWrongcode(false)
                    } else {
                        setVerified(false)
                        setWrongcode(true)
                    }
                }
            }
            if (domainsettings.newdomain) {
                if (prevValue.domainsettings.newdomain !== domainsettings.newdomain) {
                    setActiveStep(0);
                    setEmail("")
                    setChecked(false)
                    setSave(false)
                    setDisableemail(true)
                    setVerified(false)
                    setWrongcode(false)
                    setToken("")
                    setDkims([])
                    setDomain("")
                    props.sendopendomain(false, true)
                }
            }
        }
      }
  }, [domainsettings, email])

  const sideList = () => (
    <div className={classes.list}>
        <Toolbar style={{height: 64, backgroundColor: '#039be5'}}>
        <h2 className={classes.titulo}>{intl.formatMessage({ id: "dom.connect" })}</h2>
        <Fab disableRipple size="small" elevation={0} aria-label="close" className={classes.close} onClick={toggleDrawer(false)}>
            <Close/>
        </Fab>
        </Toolbar>
        <LinearProgress style={save ? {visibility: 'visible'} : {visibility: 'hidden'}} color="secondary" />
        <div style={{flex: 'none', padding: '30px 40px', flex: '1 1 0%', minHeight: 'auto', overflow: 'auto', flexGrow: 1}}>
        <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
            <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                <div> {
                    getStepContent(index, email, handleInput, handleChange, checked, save, verified, wrongcode, token, dkims, domain)
                } </div>
                {!(activeStep === steps.length - 1) &&
                    <div className={classes.actionsContainer}>
                        <div>
                        <MyButton
                            style={{marginRight: 12}}
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                        >
                            {intl.formatMessage({ id: "dom.back" })}
                        </MyButton>
                        {(activeStep === steps.length - 2) ? <MyButton disabled={verified} onClick={handleEmailValidate} style={{marginRight: 12}}>{intl.formatMessage({ id: "dom.ver" })}</MyButton> : null}
                        <MyButton
                            disabled={disableemail || save || (activeStep === steps.length - 2 && !verified)}
                            variant="contained"
                            color="primary"
                            onClick={activeStep === steps.length - 3 ? handleNext : (activeStep === steps.length - 2 ? handleNext2 : handleNext3)}
                            className={classes.button}
                        >
                            {activeStep === steps.length - 1 ? intl.formatMessage({ id: "dom.finish" }) : intl.formatMessage({ id: "dom.next" })}
                        </MyButton>
                        </div>
                    </div>
                }
                </StepContent>
            </Step>
            ))}
        </Stepper>
        {activeStep === steps.length && (
            <Paper square elevation={0} className={classes.resetContainer}>
            <Typography>{intl.formatMessage({ id: "dom.allsteps" })}</Typography>
            <MyButton onClick={handleReset} className={classes.button}>
                {intl.formatMessage({ id: "dom.reset" })}
            </MyButton>
            </Paper>
        )}
        </div>
        <Toolbar style={{width: '100%', backgroundColor: '#f5f8fa', borderTop: '1px solid #cbd6e2', paddingBottom: 20, paddingTop: 20}}>
            <ButtonGroup
            fullWidth
            color="secondary"
            size="large"
            aria-label="large full width outlined secondary button group">
            <Button disabled={!checked || save} onClick={toggleAdd(true)} style={{backgorund: '#ffe5e5'}}>{intl.formatMessage({ id: "dom.connect" })}</Button>
            <Button onClick={toggleDrawer(false)}>{intl.formatMessage({ id: "acc.cancel" })}</Button>
            </ButtonGroup>
        </Toolbar>
    </div>
    );

    return (
        <div>
        <Drawer anchor="right" open={props.open} onClose={toggleDrawer(false)}>
            {sideList()}
        </Drawer>
        </div>
    );
}