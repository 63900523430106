// jshint ignore: start
import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import Close from '@material-ui/icons/Close'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import LinearProgress from '@material-ui/core/LinearProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from "react-redux";
import { setNotification } from "../state/store/actions/notification";
import { FormattedNumber, Link } from "gatsby-plugin-intl"
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'


const useStyles = makeStyles({
  paper: {
    width: '100%',
    padding: 20,
    margin: 6,
    display: 'flex',
    cursor: 'pointer'
  },
  loading: {
    visibility: 'visible'
  },
  completed: {
    visibility: 'hidden'
  },
  list: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    wordWrap: 'break-word',
    height: '100%'
  },
  titulo: {
    flex: 1,
    color: 'white'
  },
  close: {
    marginLeft: 'auto',
    color: 'white',
    boxShadow: 'none',
    background: 'none',
    '&:hover, &focusVisible': {
      background: 'rgb(0,0,0,0.25)'
    },
    '&:active, &focusVisible': {
      background: 'rgb(0,0,0,0.1)',
      boxShadow: 'none'
    }
  },
  dns: {
    margin: '10px -10px 10px -30px',
    border: '2px solid rgb(3, 155, 229)',
    borderRadius: 4,
    padding: 6
  },
  form: {
    top: 0,
    flex: '1 0 auto',
    height: '100%',
    display: 'flex',
    outline: 0,
    zIndex: 1200,
    overflowY: 'auto',
    flexDirection: 'column',
    // -webkit-overflow-scrolling: touch;
  },
  root: {
    margin: '4px 0 8px 0'
  },
  label: {
    marginLeft: '-42px',
    padding: '5px 5px 5px 40px;',
    borderRadius: '5px',
    border: '1px solid #53607E',
    width: 420
  },
  labelSel: {
    backgroundColor: 'rgb(250, 225, 255, 0.5)'
  }
});

export default function AddPayment(props) {
  const classes = useStyles();
  const [selPlan, setSelPlan] = useState('free');
  const [stripe, setStripe] = useState(null);
  const intl = props.intl
  const navigate = props.navigate
  const dispatch = useDispatch();

  const handleChangePlan = event => {
    props.setEditMode(true)
    setSelPlan(event.target.value);
  };

  const toggleDrawer = (open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    props.sendopenplan(open, open)
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    props.setLoading(true)
      authUser().then((user) => {
          if (user) {
            const query = {uid: user.uid, email: user.email, selPlan: selPlan}
            firebase.functions().httpsCallable('paymentIntent')({
              query
            }).then(result => {
              if (result.data) {
                const {code, payi, client_secret, pmid} = result.data
                if (code === 400) {
                  stripe.handleCardPayment(
                    client_secret, {
                      payment_method: pmid
                    }
                  ).then((result) => {
                    if (result.error) {
                      dispatch(setNotification(result.error.message, true, 'warning'))
                    } else {
                      if (result.paymentIntent.status === "succeeded") {
                        props.handleUpdatePlan(selPlan)
                        dispatch(setNotification(intl.formatMessage({id: "acc.paysuccess"}), true, 'success'))
                      }
                    }
                    props.setLoading(false)
                  });

                } else if (code === 402) {
                  props.setLoading(false)
                  props.sendopenplan(false)
                  props.sendopenpayments(true)
                } else if (code === 403) {
                  if (result.data.message) {
                    if (result.data.message === "Free Selected") {
                      dispatch(setNotification(intl.formatMessage({id: "acc.paysuccess"}), true, 'success'))
                    }
                  }
                  props.handleUpdatePlan(selPlan)
                }
              }
            });
          }
        });
  }

  const handleClick = () => {

  };

  function authUser() {
      return new Promise(function (resolve, reject) {
          firebase.auth().onAuthStateChanged(function (user) {
              if (user) {
                  resolve(user);
              } else {
                  reject('User not logged in');
                  var gu = localStorage.getItem("gatsbyUser");
                  if (gu) {
                    localStorage.setItem("gatsbyUser", JSON.stringify({}));
                  }
                  navigate("/app/login")
              }
          });
      });
  }

  function usePrevious(value) {
      const ref = useRef();
      useEffect(() => {
          if (!ref.current) {
              ref.current = true;
                if (window.Stripe) {
                  setStripe(window.Stripe('pk_live_kJcTeudTDYkZCCS8Qc27IDzd'))
                } else {
                  document.querySelector('#stripe-js').addEventListener('load', () => {
                    setStripe(window.Stripe('pk_live_kJcTeudTDYkZCCS8Qc27IDzd'))
                  });
                }
              authUser().then((user) => {
              }, (e) => {
                  console.log(e);
              });
          }
          ref.current = value
      }, [value]);
      return ref.current;
  }

  const prevValue = usePrevious({selPlan});
  useEffect(() => {
        const qstring = props.qs['changeplan'];
        if (qstring === "free" || qstring === "lite01" || qstring === "standard01" || qstring === "lite12" || qstring === "standard12") {
          setSelPlan(qstring)
          props.setQs("")
          navigate('/app/account')
        }
      if (prevValue) {
      }
  }, [selPlan])

  const labelStyle = (plan) => {
    return {
      label: props.plan === plan ? classes.label + ' ' + classes.labelSel : classes.label,
      root: classes.root
    }
  }

  const pricing = {
      eur: {free: 0, lite01: 19.00, lite12: 182.40, standard01: 29.00, standard12: 278.40, branding: 89.00},
      usd: {free: 0, lite01: 23.00, lite12: 220.80, standard01: 33.00, standard12: 316.80, branding: 99.00}
  }

  const sideList = () => (
    <div className={classes.list}>
    <Toolbar style={{height: 64, backgroundColor: '#039be5'}}>
    <h2 className={classes.titulo}>{intl.formatMessage({id: "acc.updateplan"})}</h2>
    <Fab disableRipple size="small" elevation={0} aria-label="close" className={classes.close} onClick={toggleDrawer(false)}>
        <Close/>
    </Fab>
    </Toolbar>
    <LinearProgress style={props.loading ? {visibility: 'visible'} : {visibility: 'hidden'}} color="secondary" />
    <div style={{flex: 'none', padding: '30px 40px', flex: '1 1 0%', minHeight: 'auto', overflow: 'auto', flexGrow: 1}}>
    <FormControl style={{width: '100%'}} component="fieldset">
        <RadioGroup aria-label="position" name="position" value={props.editMode ? selPlan : props.plan} onChange={handleChangePlan} column="true">
        <Typography style={{fontWeight: 600}} variant="h6" component="p">Standard</Typography>
        <Typography variant="subtitle1" component="span">{intl.formatMessage({id: "pric.standard"})}</Typography>
        <div style={{display: 'inline-flex', width: '100%'}}>
          <FormControlLabel
            disabled={props.loading}
            classes={labelStyle("standard01")}
            style={{height: '100%', magin: '12px 0', width: '49%', marginRight: '1%'}}
            value="standard01"
            control={<Radio color="primary" />}
            label={
              <div style={{display: 'inline-flex', verticalAlign: 'middle', flexDirection: 'column', alignItems: 'flex-start'}}>
                  <Typography variant="subtitle2" component="p">{intl.formatMessage({id: "acc.paymonth"})}</Typography>
                  <div>
                    <span style={{fontSize: 18, fontWeight: 700}}>
                      <FormattedNumber
                        value={pricing[props.currency]['standard01']}
                        style="currency"
                        currency={props.currency}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </span><span>{' /'}{intl.formatMessage({id: "pric.month"})}</span>
                  </div>
              </div>
            }
            labelPlacement="end"
          />
          <FormControlLabel
            disabled={props.loading}
            classes={labelStyle("standard12")}
            style={{height: '100%', magin: '12px 0', width: '49%', marginLeft: '1%'}}
            value="standard12"
            control={<Radio color="primary" />}
            label={
              <div style={{display: 'inline-flex', verticalAlign: 'middle', flexDirection: 'column', alignItems: 'flex-start'}}>
                  <Typography variant="subtitle2" component="p">{intl.formatMessage({id: "acc.payyear"})}</Typography>
                  <div>
                    <span style={{fontSize: 18, fontWeight: 700}}>
                      <FormattedNumber
                        value={pricing[props.currency]['standard12']}
                        style="currency"
                        currency={props.currency}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </span>
                    <span>{' /'}{intl.formatMessage({id: "pric.year"})}</span>
                  </div>
              </div>
            }
            labelPlacement="end"
          />
        </div>
        <Typography style={{fontWeight: 600}} variant="h6" component="p">Lite</Typography>
        <Typography variant="subtitle1" component="span">{intl.formatMessage({id: "pric.lite"})}</Typography>
        <div style={{display: 'inline-flex', width: '100%'}}>
          <FormControlLabel
            disabled={props.loading}
            classes={labelStyle("lite01")}
            style={{height: '100%', magin: '12px 0', width: '49%', marginRight: '1%'}}
            value="lite01"
            control={<Radio color="primary" />}
            label={
              <div style={{display: 'inline-flex', verticalAlign: 'middle', flexDirection: 'column', alignItems: 'flex-start'}}>
                <Typography variant="subtitle2" component="p">{intl.formatMessage({id: "acc.paymonth"})}</Typography>
                <div>
                  <span style={{fontSize: 18, fontWeight: 700}}>
                    <FormattedNumber
                      value={pricing[props.currency]['lite01']}
                      style="currency"
                      currency={props.currency}
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                    />
                  </span>
                  <span>{' /'}{intl.formatMessage({id: "pric.month"})}</span>
                </div>
              </div>
          }
            labelPlacement="end"
          />
          <FormControlLabel
            disabled={props.loading}
            classes={labelStyle("lite12")}
            style={{height: '100%', magin: '12px 0', width: '49%', marginLeft: '1%'}}
            value="lite12"
            control={<Radio color="primary" />}
            label={
              <div style={{display: 'inline-flex', verticalAlign: 'middle', flexDirection: 'column', alignItems: 'flex-start'}}>
                <Typography variant="subtitle2" component="p">{intl.formatMessage({id: "acc.payyear"})}</Typography>
                <div>
                  <span style={{fontSize: 18, fontWeight: 700}}>
                    <FormattedNumber
                      value={pricing[props.currency]['lite12']}
                      style="currency"
                      currency={props.currency}
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                    />
                  </span>
                  <span>{' /'}{intl.formatMessage({id: "pric.year"})}</span>
                </div>
              </div>
          }
            labelPlacement="end"
          />
        </div>
        <Typography style={{fontWeight: 600}} variant="h6" component="p">Free</Typography>
        <Typography variant="subtitle1" component="span">{intl.formatMessage({id: "pric.free"})}</Typography>
        <FormControlLabel
            disabled={props.loading}
            color="secondary"
            classes={labelStyle("free")}
            style={{height: '100%', magin: '12px 0'}}
            value="free"
            control={<Radio color="primary" />}
            label={
              <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={{display: 'inline-flex', verticalAlign: 'middle', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <div style={{display: 'block', fontWeight: 700}}>{intl.formatMessage({id: "acc.freeplan"})}</div>
                </div>
                <div style={{fontSize: 18, fontWeight: 700, display: 'inline-flex', flex: 1, verticalAlign: 'middle', flexDirection: 'column', marginLeft: 'auto', alignItems: 'flex-end'}}>
                  <span>
                    <FormattedNumber
                      value={0}
                      style="currency"
                      currency={props.currency}
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                    />
                    <span style={{fontSize: '1rem', fontWeight: 400}}>{' /'}{intl.formatMessage({id: "pric.year"})}</span>
                  </span>
                </div>
              </div>
            }
            labelPlacement="end"
          />
      </RadioGroup>
    </FormControl>
    <Typography style={{textAlign: 'center'}} component="div" variant="caption">{intl.formatMessage({id: "foot.seepricing"})}{' '}<Link style={{fontWeight: 600, color: "black"}} to="/pricing">{intl.formatMessage({id: "pric.pricing"})}</Link></Typography>
    </div>
    <Toolbar style={{width: '100%', backgroundColor: '#f5f8fa', borderTop: '1px solid #cbd6e2', paddingBottom: 20, paddingTop: 20}}>
    <form className={classes.form} onSubmit={handleSubmit}>
        <ButtonGroup
        fullWidth
        color="secondary"
        size="large"
        aria-label="large full width outlined secondary button group">
          <Button disabled={!props.editMode || props.plan === selPlan || props.loading} type="submit" onClick={handleClick} style={{backgorund: '#ffe5e5'}}>{intl.formatMessage({id: "acc.update"})}</Button>
          <Button onClick={toggleDrawer(false)}>{intl.formatMessage({id: "acc.cancel"})}</Button>
        </ButtonGroup>
      </form>
    </Toolbar>
    </div>
    );
    return (
        <div>
        <Drawer anchor="right" open={props.open} onClose={toggleDrawer(false)}>
            {sideList()}
        </Drawer>
        </div>
    );
}