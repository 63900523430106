// jshint ignore: start
import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer'
import FormLabel from '@material-ui/core/FormLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import Close from '@material-ui/icons/Close'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import LinearProgress from '@material-ui/core/LinearProgress';
import { useDispatch } from "react-redux";
import * as firebase from 'firebase/app'
import Typography from '@material-ui/core/Typography';
import { setNotification } from "../state/store/actions/notification";
import { navigate } from 'gatsby-plugin-intl'
import 'firebase/auth'
import 'firebase/functions'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  Elements,
  StripeProvider
} from 'react-stripe-elements';
import './addPaymentStyles.css';

const useStyles = makeStyles({
  loading: {
    visibility: 'visible'
  },
  completed: {
    visibility: 'hidden'
  },
  list: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    wordWrap: 'break-word',
    height: '100%'
  },
  titulo: {
    flex: 1,
    color: 'white'
  },
  close: {
    marginLeft: 'auto',
    color: 'white',
    boxShadow: 'none',
    background: 'none',
    '&:hover, &focusVisible': {
      background: 'rgb(0,0,0,0.25)'
    },
    '&:active, &focusVisible': {
      background: 'rgb(0,0,0,0.1)',
      boxShadow: 'none'
    }
  },
  dns: {
    margin: '10px -10px 10px -30px',
    border: '2px solid rgb(3, 155, 229)',
    borderRadius: 4,
    padding: 6
  },
  form: {
    top: 0,
    flex: '1 0 auto',
    height: '100%',
    display: 'flex',
    outline: 0,
    zIndex: 1200,
    overflowY: 'auto',
    flexDirection: 'column',
    // -webkit-overflow-scrolling: touch;
  }
});

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
        padding,
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};

export default function AddPayment(props) {
  const classes = useStyles();
  const [save, setSave] = useState(false);
  const [stripe, setStripe] = useState(null);
  const [ready, setReady] = useState(false);
  const [card, setCard] = useState(null);
  const [error, setError] = useState(null);
  const intl = props.intl
  const dispatch = useDispatch();

  const toggleDrawer = (open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setReady(false)
    props.sendopenpayments(open, open)
  };

  const handleBlur = () => {
  };
  const handleChange = (change) => {
    setError("")
  };
  const handleClick = () => {
  };
  const handleFocus = () => {
  };
  const handleReady = () => {
    setReady(true)
  };
  const handleCardReady = (evt) => {
    setCard(evt)
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    props.setLoading(true)
    setSave(true)
    if (stripe) {
      const data = {
        payment_method_data: {
          billing_details: {
            name: 'Acroleads User'
          },
        }
      }
      stripe
        .handleCardSetup(props.clientSecret.client_secret, card, data)
        .then((payload) => {
          if (payload.error) {
            setError(payload.error.code)
            if (payload.error.code) {
              dispatch(setNotification(payload.error.message + ' ' + intl.formatMessage({id: "acc.validcard"}), true, 'warning'))
            }
            props.setLoading(false)
            setSave(false)
          } else {
            if (!props.card) {
              handleCustomer(payload.setupIntent.payment_method)
            }
          }
        })

    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  const handleCustomer = (pm) => {
    props.authuser().then((user) => {
      if (user) {
        const query = {
          uid: user.uid,
          email: user.email,
          seti: props.clientSecret.seti,
          clientSecret: props.clientSecret.client_secret,
          pm: pm,
          card: true
        }
        firebase.functions().httpsCallable('updatePaymentMethod')({
          query
        }).then(result => {
          if (result.data) {
            const {updated, code} = result.data
            if (code === 400) {
              props.getPayment()
              props.sendopenpayments(false)
            } else if (result.data.code === 402) {
              
            }
          }
        props.setLoading(false)
        setSave(false)
        });
      }
    })
  }

  function authUser() {
      return new Promise(function (resolve, reject) {
          firebase.auth().onAuthStateChanged(function (user) {
              if (user) {
                  resolve(user);
              } else {
                  reject('User not logged in');
                  var gu = localStorage.getItem("gatsbyUser");
                  if (gu) {
                    localStorage.setItem("gatsbyUser", JSON.stringify({}));
                  }
                  navigate("/app/login")
              }
          });
      });
  }

  function usePrevious(value) {
      const ref = useRef();
      useEffect(() => {
          if (!ref.current) {
              ref.current = true;
               if (window.Stripe) {
                  setStripe(window.Stripe('pk_live_kJcTeudTDYkZCCS8Qc27IDzd'))
               } else {
                 document.querySelector('#stripe-js').addEventListener('load', () => {
                  setStripe(window.Stripe('pk_live_kJcTeudTDYkZCCS8Qc27IDzd'))
                 });
               }
              authUser().then((user) => {
              }, (e) => {
                  console.log(e);
              });
          }
          ref.current = value
      }, [value]);
      return ref.current;
  }

  const prevValue = usePrevious({});
  useEffect(() => {
      if (prevValue) {

      }
  }, [])

  const sideList = () => (
    <StripeProvider {...{stripe}}>
        <Elements>
            <form className={classes.form} onSubmit={handleSubmit}>
              <div className={classes.list}>
                <Toolbar style={{height: 64, backgroundColor: '#039be5'}}>
                <h2 className={classes.titulo}>{intl.formatMessage({id: "acc.updatepay"})}</h2>
                <Fab disableRipple size="small" elevation={0} aria-label="close" className={classes.close} onClick={toggleDrawer(false)}>
                    <Close/>
                </Fab>
                </Toolbar>
                <LinearProgress style={props.loading ? {visibility: 'visible'} : {visibility: 'hidden'}} color="secondary" />
                <div style={{flex: 'none', padding: '30px 40px', flex: '1 1 0%', minHeight: 'auto', overflow: 'auto', flexGrow: 1}}> 
                <FormLabel error={error === "incomplete_number"}>
                    {intl.formatMessage({id: "acc.cardnum"})}
                    <CardNumberElement
                      disabled={props.loading}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onReady={handleCardReady}
                      {...createOptions(props.fontSize)}
                    />
                  </FormLabel>
                  <FormLabel error={error === "incomplete_expiry"}>
                    {intl.formatMessage({id: "acc.cardexp"})}
                    <CardExpiryElement
                      disabled={props.loading}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onReady={handleReady}
                      {...createOptions(props.fontSize)}
                    />
                  </FormLabel>
                  <FormLabel error={error === "incomplete_cvc"}>
                    CVC
                    <CardCVCElement
                      disabled={props.loading}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onReady={handleReady}
                      {...createOptions(props.fontSize)}
                    />
                  </FormLabel>
                <div>
                <Typography
                  style={{ marginBottom: 10 }}
                  color="inherit"
                  variant="caption"
                  component="p"
                >
                {intl.formatMessage({id: "acc.auth"})}
                </Typography>
                </div>
                </div>
                <Toolbar style={{width: '100%', backgroundColor: '#f5f8fa', borderTop: '1px solid #cbd6e2', paddingBottom: 20, paddingTop: 20}}>
                <ButtonGroup
                    fullWidth
                    color="secondary"
                    size="large"
                    aria-label="large full width outlined secondary button group">
                    <Button disabled={!props.clientSecret.client_secret || !ready || save} type="submit" onClick={handleClick} style={{backgorund: '#ffe5e5'}}>{props.card ? intl.formatMessage({id: "acc.update"}) : intl.formatMessage({id: "acc.save"})}</Button>
                    <Button onClick={toggleDrawer(false)}>{intl.formatMessage({id: "acc.cancel"})}</Button>
                    </ButtonGroup>
                </Toolbar>
              </div>
            </form>
        </Elements>
      </StripeProvider>
    );
    return (
        <div>
        <Drawer anchor="right" open={props.open} onClose={toggleDrawer(false)}>
            {sideList()}
        </Drawer>
        </div>
    );
}