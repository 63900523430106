// jshint ignore: start
import React, { useState, useRef, useEffect } from 'react';
import styles from './index.module.css';
import MyButton from '../../tools/MyButton'
import { useSelector, useDispatch } from "react-redux";
import { requestUploadImage } from "../../state/store/actions/email";
import { setNotification } from "../../state/store/actions/notification";
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import PhotoIcon from '@material-ui/icons/PhotoSizeSelectActualOutlined'
import {navigate} from 'gatsby-plugin-intl'
import firebase from 'firebase/app'
import 'firebase/auth'

export default function ImageAdd(props) {
  const [url, setUrl] = useState('');
  const [link, setLink] = useState('');
  const [open, setOpen] = useState(false);
  const [uploadImage, setUploadImage] = useState('')
  const [uploading, setUploading] = useState(false)
  var node = useRef(null);
  var node2 = useRef(null);
  const email = useSelector(state => state.email);
  const dispatch = useDispatch();

  const handleClick = () => {
    setOpen(prev => !prev);
  };

  const handleClickOutside = (e) => {
    if (node.contains && node.contains(e.target) || node2.contains && node2.contains(e.target)) {
      return
    }
    if (open) {
      setOpen(false)
      setUploading(false)
    }
  }

  const addImage = () => {
    const { editorState, onChange } = props;
    onChange(props.modifier(editorState, url, link));
    setUrl("")
    setLink("")
    setOpen(false)
  };

  const changeUrl = (evt) => {
    setUrl(evt.target.value)
  }

  const changeLink = (evt) => {
    setLink(evt.target.value)
  }

  const handleFormReset = (e) => {
    //e.target.value = ""
  }

  const handleUploadChange = (e) => {
    var file = e.target.files[0]

    var reader = new FileReader();
    setUploading(true)
    setUrl('')
    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => {

        if (file.size > 1024000) {
          dispatch(setNotification("File is to big!", true, 'warning'))
          setUploadImage("")
          return
        }

        setUploadImage(reader.result)
        dispatch(requestUploadImage(props.emailid, reader.result))
    }

    if (file) {
      reader.readAsDataURL(file);
    } else {
      setUploadImage("")
    }
  }

  function authUser() {
    return new Promise(function (resolve, reject) {
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          resolve(user);
        } else {
          reject('User not logged in');
          var gu = localStorage.getItem("gatsbyUser");
          if (gu) {
            localStorage.setItem("gatsbyUser", JSON.stringify({}));
          }
          navigate("/app/login")
        }
      });
    });
  }

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    if (!ref.current) {
      ref.current = true;
      authUser().then((user) => {
        // dispatch(requestEmailSettings())
      }, (e) => {
        console.log(e);
      });
    }
    ref.current = value
  }, [value]);
  return ref.current;
}

  const prevValue = usePrevious({open, email, url});
  useEffect(() => {
    if (prevValue) {
      if (prevValue.email !== email) {
        const url = email.image.data
        setUrl(url)
        setUploading(false)
      }
    }
  }, [open, email, url])

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const buttonClassName = open ? styles.addImagePressedButton : styles.addImageButton;

  return (
    <div className={styles.addImage}>
      <Tooltip title="Add Image" >
        <button
          ref={(element) => { node2 = element; }}
          className={buttonClassName}
          onClick={handleClick}
          type="button"
        >
        <PhotoIcon/>
      </button>
      </Tooltip>
      {open &&
      <div
        ref={(element) => { node = element; }}
        className={styles.addImagePopover}
      >
        <MyButton
          disabled={uploading}
          style={{marginRight: 10, border: '1px dashed', background: 'none', color: 'gray', width: '100%'}}
          variant="contained"
          component="label"
        >
        Upload Logo
        <form onClick={handleFormReset}>
          <input
            as="binary"
            id="my-file-input"
            type="file"
            accept = ".png, .jpg, .jpeg"
            style={{ display: "none" }}
            onChange={handleUploadChange}
          />
        </form>
        </MyButton>
        <input
          disabled={uploading}
          type="text"
          placeholder="Paste the image url …"
          className={styles.addImageInput}
          onChange={changeUrl}
          value={url}
        />
        <input
          disabled={uploading}
          type="text"
          placeholder="Paste the click link …"
          className={styles.addImageInput}
          onChange={changeLink}
          value={link}
        />
        { uploading ?
          <div style={{width: '100%', textAlign: 'center', paddingTop: 10}}>
            <CircularProgress style={{height: 30, width: 30}} className={styles.progress} color="secondary" />
          </div>
         : 
        <button
          disabled={uploading}
          className={styles.addImageConfirmButton}
          type="button"
          onClick={addImage}
        >
          Add
        </button>
        }
      </div>
      }      
    </div>
  );
}