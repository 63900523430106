// jshint ignore: start
import React, { useState, useRef, useEffect } from 'react';
import Toolbar from "@material-ui/core/Toolbar"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { SketchPicker } from "react-color"
import MyButton from '../tools/MyButton'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MyInput from '../tools/MyInput'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useSelector, useDispatch } from "react-redux";
import { saveLogo, deleteLogo, requestEmailSettings, updateEmailSettings } from "../state/store/actions/emailsettings";
import { setNotification } from "../state/store/actions/notification";
import MoonLoader from 'react-spinners/MoonLoader';
import RotateLoader from 'react-spinners/RotateLoader';
import { useIntl, navigate } from 'gatsby-plugin-intl';
import * as firebase from 'firebase/app'
import 'firebase/auth'
import SEO from "../components/seo"

const useStyles = makeStyles({
  bigAvatar: {
    width: 60,
    height: 60,
  },
  colorCircle: {
    position: 'relative',
    height: 39,
    width: 39,
    borderRadius: '50%',
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: 10,
    marginBottom: 2,
    cursor: 'pointer',
    '&::after': {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: 39,
      height: 39,
      border: '1px solid rgba(152, 152, 152, 0.25)',
      borderRadius: '50%',
      content: `''`,
      position: 'absolute',
      mixBlendMode: 'difference'
    }
  },
  formroot: {
    display: 'block',
  },
  override: {
    '& > div:first-child': {
      height: 70,
      width: 70,
      position: 'absolute',
      top: '-5px',
      left: '-5px'
    },
    '& > div > div:first-child': {
      height: 5,
      width: 5,
    },
    '& > div > div:last-child': {
      borderWidth: 5
    }
  },
  baseloading: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, .70)'
  },
  override2: {
    display: 'block',
    margin: 'auto auto',
    borderColor: 'red',
  },
});

const DEFAULT_COLOR = ''
const DEFAULT_COLOR_NEW = ''

const THEME_COLORS = [
  'transparent',
  '#ffffff',
  '#3f51b5',
  '#ff4081',
  '#e51c23',
  '#009688',
  '#259b24',
  '#8bc34a',
  '#ff9800',
]

function RGBAToHexA(r, g, b, a) {
  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);
  a = Math.round(a * 255).toString(16);

  if (r.length == 1)
    r = "0" + r;
  if (g.length == 1)
    g = "0" + g;
  if (b.length == 1)
    b = "0" + b;
  if (a.length == 1) {
    a = "0" + a;
  }

  if (a === 'ff') {
    a = ''
  }

  return "" + r + g + b + a;
}

function myHexColor(color) {
  if (color === "transparent") {
    return "transparent"
  }
  const init = color.indexOf('(');
  const fin = color.indexOf(')');

  var r = 0
  var g = 0
  var b = 0
  var a = 0

  const arraycolor = color.substring(init + 1, fin).split(',')
  arraycolor.map((n, i) => {
    if (i == 0)
      r = parseInt(n);
    if (i == 1)
      g = parseInt(n);
    if (i == 2)
      b = parseInt(n);
    if (i == 3)
      a = parseFloat(n);
  })
  return RGBAToHexA(r, g, b, a)
}

export default function EmailSettings() {
    const [color, setColor] = useState(DEFAULT_COLOR)
    const [newcolor, setNewColor] = useState(DEFAULT_COLOR_NEW)
    const [showColorPicker, setShowColorPicker] = useState(false)
    const [logo, setLogo] = useState('')
    const [newlogo, setNewLogo] = useState('')
    const [logoname, setLogoName] = useState('')
    const [uploading, setUploading] = useState(false)
    const [title, setTitle] = useState('')
    const [facebook, setFacebook] = useState('')
    const [instagram, setInstagram] = useState('')
    const [twitter, setTwitter] = useState('')
    const [medium, setMedium] = useState('')
    const [allowsave, setAllowSave] = useState(true)
    const [loading, setLoading] = useState(false)
    const [acronym, setAcronym] = useState("")
    const intl = useIntl();
    const emailsettings = useSelector(state => state.emailsettings);
    const dispatch = useDispatch();

    const handleColorPicker = () => setShowColorPicker(!showColorPicker)

    const handleChange = (event) => {
          setNewColor(event.currentTarget.value)
          setColor('#' + event.currentTarget.value)
    }

    const handleChangeColor = (color, event) => {
      const myHex = RGBAToHexA(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a)
      setNewColor(myHex)
      setColor("#" + myHex)
    }

    const handleChangeColorComplete = (color, event) => {
      const myHex = RGBAToHexA(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a)
      setColor(myHex)
      setNewColor(myHex)
    }

    const maxLengthCheck = (object) => {
           if (object.target.value.length > 8) {
             object.target.value = object.target.value.slice(0, 8)
           }
           if (object.target.value.length === 0) {
              setNewColor('fff')
              setColor('#fff')
           }
     }

    const firstMethod = (e) => {
      const re = /^[0-9A-Fa-f]+$/;
      if (!re.test(e.key)) {
        e.preventDefault();
      }
    }

    const handleUploadChange = (e) => {
      var file = e.target.files[0]

      var reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {

          if (file.size > 1024000) {
            dispatch(setNotification(intl.formatMessage({ id: "eset.tobig" }), true, 'warning'))
            setLogo("")
            return
          };

          setLogo(reader.result)
          setLogoName(file.name)
          setNewLogo('')
      }

      if (file) {
        reader.readAsDataURL(file);
      } else {
        setLogo("")
      }
    }

    const handleUpdateSettings = () => {
      setLoading(true)
      dispatch(updateEmailSettings(newcolor, title, facebook, instagram, twitter, medium))
    }

    const handleSaveLogo = () => {
      setUploading(true)
      dispatch(saveLogo(logo))
    }

    const handleDeleteLogo = () => {
      setUploading(true)
      dispatch(deleteLogo(logo))
    }

    const handleCancelLogo = () => {
      if (localStorage.getItem('emailsettings')) {
        const local = JSON.parse(localStorage.getItem('emailsettings'))
        setLogo(local['logo'])
      } else {
        setLogo("")
      }
      setLogoName("")
    }

    const handleFormReset = (e) => {
      e.target.value = ""
    }

    const handleEmailTitle = (e) => {
      setTitle(e.target.value)
    }

    var m = new Map()
    m.set('a', 1)

    const handleSocialLinks = (e) => {
      const value = e.target.value
      switch (e.target.id) {
        case 'facebook':
          setFacebook(value)
          break;
        case 'instagram':
          setInstagram(value)
          break;
        case 'twitter':
          setTwitter(value)
          break;
        case 'medium':
          setMedium(value)
          break;
        default:
          break;
      }

    }

    const classes = useStyles();

    function authUser() {
      return new Promise(function (resolve, reject) {
        firebase.auth().onAuthStateChanged(function (user) {
          if (user) {
            resolve(user);
          } else {
            reject('User not logged in');
            var gu = localStorage.getItem("gatsbyUser");
            if (gu) {
              localStorage.setItem("gatsbyUser", JSON.stringify({}));
            }
            navigate("/app/login")
          }
        });
      });
    }

    function usePrevious(value) {
      const ref = useRef();
      useEffect(() => {
        if (!ref.current) {
          ref.current = true;          
          authUser().then((user) => {
            var str = user.displayName
            var matches = str.match(/\b(\w)/g);
            setAcronym(matches.join(''))
            dispatch(requestEmailSettings())
            setUploading(true)
          }, (e) => {
            console.log(e);
          });
        }
        ref.current = value
      }, [value]);
      return ref.current;
    }

    const prevValue = usePrevious({emailsettings, title, color, facebook, instagram, twitter, medium});
    useEffect(() => {
        if (prevValue) {
          const settings = emailsettings['settings'];
          const mimetypelogo = settings.logourl.split("?alt");
          if (prevValue.emailsettings.settings !== settings) {
            localStorage.setItem('emailsettings', JSON.stringify({
               bgcolor :settings.bgcolor,
               logo: settings.logourl,
               contentType: 'image/' + mimetypelogo[0].substring(mimetypelogo[0].lastIndexOf(".") + 1)
            }));
            setColor(settings.bgcolor)
            setNewColor(settings.bgcolor.substring(1))
            setTitle(settings.title)
            setFacebook(settings.social.facebook)
            setInstagram(settings.social.instagram)
            setTwitter(settings.social.twitter)
            setMedium(settings.social.medium)
            setLogo(settings.logourl)
            setUploading(false)
          }
          if (prevValue['emailsettings']['logo'] !== emailsettings['logo']) {
            dispatch(setNotification(intl.formatMessage({ id: "eset.photoupdated" }), true, 'success'))
            setUploading(false)
            setNewLogo(logo)
            setLogo('')
          }
          if (prevValue['emailsettings']['deleted'] !== emailsettings['deleted']) {
            dispatch(setNotification(intl.formatMessage({ id: "eset.photodeleted" }), true, 'success'))
            setUploading(false)
            setNewLogo('')
            setLogo('')
            setLogoName('')
          }
          if (prevValue['emailsettings']['updated'] !== emailsettings['updated']) {
            setLoading(false)
            dispatch(requestEmailSettings())
            dispatch(setNotification(intl.formatMessage({ id: "eset.esetupdated" }), true, 'success'))
          }
          if (
            title !== settings.title ||
            color !== settings.bgcolor ||
            facebook !== settings.social.facebook ||
            instagram !== settings.social.instagram ||
            twitter !== settings.social.twitter ||
            medium !== settings.social.medium
          ) {
            setAllowSave(false)
          } else {
            setAllowSave(true)
          }
        }
    }, [emailsettings, title, color, facebook, instagram, twitter, medium])

    return (
      <div>
        <SEO title={intl.formatMessage({id: "eset.seo"})} />
        <Toolbar >
          <h1 style={{flex: 1}}>{intl.formatMessage({id: "eset.seo"})}</h1>
          <div style={{marginLeft: 'auto'}}>
            <Button disabled={allowsave} onClick={handleUpdateSettings}  variant="outlined" color="secondary">
                {intl.formatMessage({ id: "acc.save" })}
            </Button>
          </div>
        </Toolbar>
        <Divider/>
        <div>
        {/* LOGO */}
        <div style={{padding: '20px 0'}}>
          <Typography style={{marginBottom: 10}} color="inherit" variant="h6" component="p">{intl.formatMessage({ id: "eset.logo" })}</Typography>
          
           <div style={{width: 70, height: 70, position: 'relative'}}>
            <Avatar alt="Remy Sharp" src={newlogo ? newlogo : logo} className={classes.bigAvatar}>{acronym}</Avatar>
            <div className={classes.override}>
              <MoonLoader
                sizeUnit={"px"}
                size={70}
                color={'red'}
                loading={uploading}
                margin={'0px'}
              />
              </div>
            </div>
          <Typography style={{marginTop: 5, marginBottom: 10}} color="inherit" variant="subtitle1" component="p">{logoname}</Typography>
          { (!logo || (logo && !logoname)) &&
            <div>
              <MyButton
                style={{marginRight: 10}}
                variant="contained"
                component="label"
              >
              {intl.formatMessage({ id: "eset.uploadlogo" })}
              <form onClick={handleFormReset}>
                <input
                  as="binary"
                  id="my-file-input"
                  type="file"
                  accept = ".png, .jpg, .jpeg"
                  style={{ display: "none" }}
                  onChange={handleUploadChange}
                />
              </form>
              </MyButton>
              {(logo || newlogo) &&
                <Button disabled={uploading} onClick={handleDeleteLogo} style={{textTransform: 'none', fontSize: 15}} variant="outlined" disableRipple>{intl.formatMessage({ id: "eset.deleteimage" })}</Button>
              }
            </div>
          }
          {(logo && logoname) &&
            <div>
              <MyButton disabled={uploading} onClick={handleSaveLogo} style={{marginRight: 10}}>{intl.formatMessage({ id: "acc.save" })}</MyButton>
              <Button disabled={uploading} style={{textTransform: 'none', fontSize: 15}} onClick={handleCancelLogo} variant="outlined" disableRipple>{intl.formatMessage({ id: "acc.cancel" })}</Button>
            </div>
          }
          <Typography style={{marginTop: 10}} color="inherit" variant="subtitle2" component="p">{intl.formatMessage({ id: "eset.limit" })}</Typography>
        </div>
        <Divider/>

        {/* BGCOLOR */}
        <div style={{padding: '20px 0'}}>
          <Typography style={{marginBottom: 10}} color="inherit" variant="h6" component="p">{intl.formatMessage({ id: "eset.bgcolor" })}</Typography>
               <span style={{marginRight: 10, fontSize: 17}}>#</span>
          <MyInput type="text" onChange={handleChange} onInput={maxLengthCheck} onKeyPress={firstMethod} style={{marginRight: 10, width: 110}} value={newcolor}/>
          <span className={classes.colorCircle} style={{
            backgroundColor: ((color === 'rgba(255, 255, 255, 1)') || ('transparent'.includes(color)) || (color === '#') ? '#ffffff' : color),}} onClick={handleColorPicker}>
          </span>
          <span style={{position: 'relative'}}>
          {
            showColorPicker &&
            <ClickAwayListener onClickAway={handleColorPicker}>
            <span
              style={{
                position: 'absolute',
                zIndex: 99999,
                top: '-132px',
                left: 0,
              }}>
              {
                /*
              <ColorPicker
                color={color}
                onChange={handleColor}
                onConfirm={handleConfirm}
                themeColors={THEME_COLORS}
                customColors={THEME_COLORS}
                customColorsHeaderText="Custom Colors"
                headerText={intl.formatMessage({id: "eset.choosecolor"})}
              />

                */
              }
            <SketchPicker
              color={ color }
              onChange={ handleChangeColor }
              onChangeComplete={ handleChangeColorComplete }
            />
            </span>
            </ClickAwayListener>
          }
          </span>
        </div>
          <Divider/>
        {/* Email Title */}
        <div style={{padding: '20px 0'}}>
          <Typography style={{marginBottom: 10}} color="inherit" variant="h6" component="p">{intl.formatMessage({ id: "eset.etitle" })}</Typography>
          <MyInput onChange={handleEmailTitle} value={title}></MyInput>
        </div>
        <Divider/>
        {/* Social Links */}
        <div style={{padding: '20px 0'}}>
          <Typography style={{marginBottom: 10}} color="inherit" variant="h6" component="p">{intl.formatMessage({ id: "eset.slinks" })}</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormControl className={classes.formroot}>
                <InputLabel shrink htmlFor="facebook-input1">
                    Facebook
                </InputLabel>
                <MyInput onChange={handleSocialLinks} value={facebook} id="facebook" style={{width: '100%'}}/>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl className={classes.formroot}>
                <InputLabel shrink htmlFor="instagram-input2">
                    Instagram
                </InputLabel>
                <MyInput onChange={handleSocialLinks} value={instagram} id="instagram" style={{width: '100%'}}/>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={4}>
              <FormControl className={classes.formroot}>
                <InputLabel shrink htmlFor="twitter-input3">
                    Twitter
                </InputLabel>
                <MyInput onChange={handleSocialLinks} value={twitter} id="twitter" style={{width: '100%'}}/>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl className={classes.formroot}>
                <InputLabel shrink htmlFor="medium-input4">
                    Medium
                </InputLabel>
                <MyInput onChange={handleSocialLinks} value={medium} id="medium" style={{width: '100%'}}/>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
          </Grid>
        </div>
        </div>
        <Divider/>
        {/* Save */}
        <div style={{padding: '20px 0'}}>
         <MyButton disabled={allowsave} onClick={handleUpdateSettings} value="">{intl.formatMessage({ id: "acc.save" })}</MyButton>
        </div>
        {loading &&
           <div className={classes.baseloading}>
            <div style={{paddingLeft: '240px', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <RotateLoader
                css={classes.override2}
                sizeUnit={"px"}
                size={15}
                color={'#f5005780'}
                loading={loading}
              />
            </div>
          </div>
        }
      </div>
    );
  }
