// jshint ignore: start
import React, { useState, useRef, useEffect } from 'react';
import {EditorState, RichUtils, getDefaultKeyBinding, convertToRaw, SelectionState, Modifier,} from 'draft-js';
import { globalHistory } from "@reach/router"
import Editor, { composeDecorators } from 'draft-js-plugins-editor';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import Popover from "@material-ui/core/Popover"
import createLinkPlugin from 'draft-js-anchor-plugin';
import createImagePlugin from 'draft-js-image-plugin';
import ImageAdd from './AddImageEditor';
import addImage from './AddImageEditor/addImage';
import ActionAdd from './CallToActionEditor'
import addAction from './CallToActionEditor/addAction';
import createAlignmentPlugin from 'draft-js-alignment-plugin';
import createFocusPlugin from 'draft-js-focus-plugin';
import createResizeablePlugin from 'draft-js-resizeable-plugin';
import createBlockDndPlugin from 'draft-js-drag-n-drop-plugin';
import createDividerPlugin from 'draft-js-divider-plugin';
import { stateToHTML } from "draft-js-export-html";
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from "@material-ui/core/Toolbar"
import Button from "@material-ui/core/Button"
import MyButton from '../tools/MyButton'
import { useSelector, useDispatch } from "react-redux";
import { requestEmailSettings } from "../state/store/actions/emailsettings";
import RotateLoader from 'react-spinners/RotateLoader';
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { green } from "@material-ui/core/colors"
import { storeEmail } from '../state/store/actions/email';
import { emailsettings } from '../state/store/reducers/emailsettings';
import decorateComponentWithProps from 'decorate-component-with-props';
import SendIcon from '@material-ui/icons/SendOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import ScheduleIcon from "@material-ui/icons/Schedule"
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DatePicker, { registerLocale } from "react-datepicker"
import * as firebase from 'firebase/app'
import 'firebase/auth'
import SEO from '../components/seo'
import {ItalicButton,BoldButton,HeadlineOneButton,HeadlineTwoButton,OrderedListButton,UnderlineButton,UnorderedListButton,} from 'draft-js-buttons';
import alignmentToolStyles from './AlignmentToolbarEditor/alignmentToolStyles.module.css'
import createHighlightPlugin from './highlightPlugin';
import editorStyles from './CustomInlineToolbarEditor/editorStyles.module.css';
import buttonStyles from './CustomInlineToolbarEditor/buttonStyles.module.css';
import toolbarStyles from './CustomInlineToolbarEditor/toolbarStyles.module.css';
import linkStyles from './CustomInlineToolbarEditor/linkStyles.module.css';
import 'draft-js-divider-plugin/lib/plugin.css';
import 'draft-js-image-plugin/lib/plugin.css';
import 'draft-js/dist/Draft.css';
import './../tools/gen-pushid';
import removeBlock from '../tools/removeBlock';
import SimpleDecorator from 'draft-js-simpledecorator';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MyInput from '../tools/MyInput';
import { setNotification } from "../state/store/actions/notification";
import "react-datepicker/dist/react-datepicker.css";
import { useIntl, navigate } from 'gatsby-plugin-intl';
import pt from "date-fns/locale/pt"
registerLocale("pt", pt)

// const hexColorDecorator = new SimpleDecorator(
//   function strategy(contentBlock, callback, contentState) {
//       const text = contentBlock.getText();
//       let matchArr, start;
//       const COLOR_REGEX = /#[0-9A-Fa-f]{6}/g;
//       while ((matchArr = COLOR_REGEX.exec(text)) !== null) {
//         start = matchArr.index;
//         callback(start, start + matchArr[0].length);
//       }
//   },
//   function component(props) {
//       return <span style={{color: props.decoratedText}}>{props.children}</span>
//   }
// )

const CallToActionDecorator = new SimpleDecorator(
  function strategy(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        if (entityKey === null) {
          return false;
        }
        return contentState.getEntity(entityKey).getType() === "CALLTOACTION";
      },
      callback
    );
  },
  function component(props) {
    const classes = useStyles();
    const {title, color} = props.contentState.getEntity(props.entityKey).getData()
    const textColor = (color === 'ffffff' || color === 'fdda00') ? '#000000' : 'white'
    return <div data-offset-key={props.offsetkey} style={{color: 'blue', textAlign: 'center', userSelect:'none'}}>
      <a suppressContentEditableWarning="true" contentEditable="false" style={{background: '#' + color,color: textColor}} className={classes.callLink}>
      <span>{title}</span> 
      </a>
    </div>
  }
)

const useStyles = makeStyles({
  teste: {
    margin: 2,
  },
  formroot: {
    width: 400,
    marginBottom: 20,
  },
  selectRoot: {
    paddingRight: 24,
  },
  margin: {
    marginRight: 24,
  },
  callLink: {
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    minWidth: "100px",
    display: "inline-block",
    textAlign: "center",
    textTransform: "uppercase",
    textDecoration: "none",
    padding: "12px 24px",
    cursor: "Pointer",
    borderRadius: 4,
  },
  brandingbutton: {
    color: green[500],
    padding: "0 8px",
    "&:hover": {
      boxShadow: "none",
      background: "none",
      color: green[700],
    },
  },
  poproot: {
    textAlign: "center",
  },
  poppaper: {
    width: 300,
  },
  baseloading: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, .70)",
    zIndex: 1
  },
  override: {
    display: "block",
    margin: "auto auto",
    borderColor: "red",
  },
  pickerroot: {
    width: 375,
    height: 302,
    padding: '0 24px',
    '& .react-datepicker-wrapper': {
      visibility: 'hidden',
      height: 0
    },
    '& .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle': {
      visibility: 'hidden',
      height: 0
    },
  },
  popperclass: {
    marginTop: 10
  },
  bottomBar: {
    minWidth: 36,
    borderRadius: 0,
    padding: 0,
    color: 'rgb(136, 136, 136)',
  },
  scheduled: {
    background: 'rgb(229, 241, 255)',
    '&:hover': {
      background: 'rgb(217, 234, 255);',
    }
  }
})


const highlightPlugin = createHighlightPlugin({
  background: 'none',
  padding: 'none',
  color: 'red',
  border: 'none',
  borderRadius: 'none'
});

const resizeablePlugin = createResizeablePlugin();
const alignmentPlugin = createAlignmentPlugin({theme: {alignmentToolStyles,buttonStyles}});
const focusPlugin = createFocusPlugin();
const blockDndPlugin = createBlockDndPlugin();
const dividerPlugin = createDividerPlugin();
const linkPlugin = createLinkPlugin({
  theme: linkStyles,
  placeholder: "Link..."
});
const inlineToolbarPlugin = createInlineToolbarPlugin({
  theme: { buttonStyles, toolbarStyles }
});

const decorator = composeDecorators(
  resizeablePlugin.decorator,
  alignmentPlugin.decorator,
  focusPlugin.decorator,
  blockDndPlugin.decorator
);

const imagePlugin = createImagePlugin({decorator});
const { InlineToolbar } = inlineToolbarPlugin;
const { AlignmentTool } = alignmentPlugin;

const plugins = [
  inlineToolbarPlugin,
  linkPlugin,
  blockDndPlugin,
  focusPlugin,
  alignmentPlugin,
  resizeablePlugin,
  imagePlugin,
  dividerPlugin,
  highlightPlugin
];

export default function ThemedInlineToolbarEditor(state) {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [text, setText] = useState("")
    const [subject, setSubject] = useState("")
    const [preview, setPreview] = useState("")
    const [disabled, setDisabled] = useState(true)
    const [emailId] = useState(genMCPushID())
    const [loading, setLoading] = useState(true)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [openDialog, setOpenDialog] = React.useState(false)
    const [startDate, setStartDate] = React.useState(new Date())
    const [emailDateTime, setEmailDateTime] = React.useState("")
    const [schedule, setSchedule] = React.useState(false);
    const intl = useIntl();
    var editor = useRef(null);

    function focusEditor() {
      editor.focus();
    }

    const settings = useSelector(state => state.emailsettings.settings);
    const dispatch = useDispatch();
    const classes = useStyles();

    const onChange = (editorState) => {
      setEditorState(editorState)
      //dispatch(storeEmail(emailId, editorState))
    };   
    
    const handleClick = event => {
      setAnchorEl(event.currentTarget)
    }

    // POPOVER
    const handleClose = () => {
      setAnchorEl(null)
    }
    const open = Boolean(anchorEl)
    const id = open ? "simple-popover" : undefined
    // DIALOG
    const handleClickDialogOpen = () => {
      setOpenDialog(true)
    }
    const handleDialogClose = () => {
      setOpenDialog(false)
    }
    const handleDialogAgree = () => {
      if (schedule) {
        setEmailDateTime(startDate.toString())
      } else {
        setEmailDateTime("")
      }
      handleDialogClose()
    }
    // NOW
    const handleNow = event => {
      setSchedule(event.target.checked);
    }

    const handleInput = (e) => {
      const {
        id,
        value
      } = event.target
      switch (id) {
        case 'subject':
          setSubject(value)
          break;
        case 'preview':
          setPreview(value)
        default:
          break;
      }
    }

    function authUser() {
      return new Promise(function (resolve, reject) {
        firebase.auth().onAuthStateChanged(function (user) {
          if (user) {
            resolve(user);
          } else {
            reject('User not logged in');
            var gu = localStorage.getItem("gatsbyUser");
            if (gu) {
              localStorage.setItem("gatsbyUser", JSON.stringify({}));
            }
            navigate("/app/login")
          }
        });
      });
    }

    function usePrevious(value) {
      const ref = useRef();
      useEffect(() => {
        if (!ref.current) {
          ref.current = true;
          authUser().then((user) => {
            setLoading(true)
            dispatch(requestEmailSettings())
          }, (e) => {
            console.log(e);
          });
        }
        ref.current = value
      }, [value]);
      return ref.current;
    }
    
  function _handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return true;
    }
    return false;
  }

  function _mapKeyToEditorCommand(e) {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, editorState,4, /* maxDepth */ );
      if (newEditorState !== editorState) {
        onChange(newEditorState);
      }
      return;
    }
    if (e.metaKey && e.key === 'k') {
      const newEditorState = RichUtils.toggleInlineStyle(editorState, 'HIGHLIGHT')
      if (newEditorState !== editorState) {
        onChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  }

  function _notifyAppuro(text) {
    setText(text)
  }

  function _notifyAppuroEditor(editorState) {

  }

  const handleDelete = (event) => {
    event.stopPropagation();
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const newContentState = removeBlock(contentState, selectionState.focusKey);
    const newEditorState = EditorState.push(editorState, newContentState, 'delete-character')
    onChange(newEditorState)
  }

  const handleReset = () => {
    let myEditorState = editorState
    let contentState = editorState.getCurrentContent()
    const firstBlock = contentState.getFirstBlock()
    const lastBlock = contentState.getLastBlock()
    const allSelected = new SelectionState({
      anchorKey: firstBlock.getKey(),
      anchorOffset: 0,
      focusKey: lastBlock.getKey(),
      focusOffset: lastBlock.getLength(),
      hasFocus: true
    })
    contentState = Modifier.removeRange(contentState, allSelected, 'backward')
    myEditorState = EditorState.push(editorState, contentState, 'remove-range')
    onChange(myEditorState)
  }

  const handleSendMail = () => {
    if (!subject) {
      dispatch(setNotification(intl.formatMessage({id: "email.emptysubject"}), true, 'warning'))
      return
    }

    authUser().then((user) => {
      setDisabled(true)
      if (user) {
        setLoading(true)
        const query = {
          myname: JSON.parse(localStorage.getItem('gatsbyUser')).name,
          uid: user.uid,
          general: settings.general,
          logourl: settings.logourl,
          social: settings.social,
          title: settings.title,
          bgcolor: settings.bgcolor,
          emails: "all",
          html: text,
          subject: subject,
          preview: preview,
          schedule: schedule ? emailDateTime : ""
        }
        firebase.functions().httpsCallable('sendMail')({
          query
        }).then(result => {
          if (result.data) {
            if (result.data.code === 400) {
              if (result.data.message.task === 'created') {
                dispatch(setNotification(intl.formatMessage({id: "email.sent"}), true, 'success'))
                setSubject('')
                setPreview('')
                setDisabled(false)
                setText('')
                handleReset()
                setEmailDateTime('')
                setSchedule(false)
                setLoading(false)
              }
            } else if (result.data.code === 401) {
              
            } else if (result.data.code === 402) {
              dispatch(setNotification(intl.formatMessage({id: "email.noavailable"}), true, 'warning'))
              setSubject('')
              setPreview('')
              setDisabled(false)
              setText('')
              handleReset()
              setEmailDateTime('')
              setSchedule(false)
              setLoading(false)
            }
          }
        });
      }
    })
  }

  const prevValue = usePrevious({settings, editorState});
      useEffect(() => {
        if (prevValue) {
          if (prevValue.editorState !== editorState) {
          let options = {
            blockRenderers: {
              atomic: (block) => {
                const contentState = editorState.getCurrentContent();
                if (contentState.getEntity(block.getEntityAt(0)).getType() === "CALLTOACTION") {
                  const data2 = contentState.getEntity(block.getEntityAt(0)).getData();
                  const { link, color, title } = data2;
                  const textColor = (color === 'ffffff' || color === 'fdda00') ? '#000000' : 'white'
                  const myBorder = (color === 'ffffff') ? ';border: 1px solid #ccc' : ';border: none'

                  const entityKey = contentState.getLastCreatedEntityKey();

                  let entitiess = [];
                  let alignment = "";
                  contentState.getBlockMap().forEach(block => {
                    block.findEntityRanges(character => {
                      const charEntity = character.getEntity();
                      if (charEntity) {
                        const contentEntity = contentState.getEntity(charEntity);
                        entitiess.push(contentEntity);
                      }
                    });
                  });
                  entitiess.forEach((entity, i) => {
                    if (entityKey > 1) {
                      if (entityKey - 2 === i) {
                        if (entity.get('type') === 'IMAGE') {
                          const data = entity.get('data');
                          if (data.alignment) {
                            alignment = data.alignment
                          }
                        }
                      }
                    }
                  })
                  const aligncoiso = ';padding-top: 15px'

                  const callStyle = 'background-color:#' + color +
                    ';color:' + textColor +
                    myBorder +
                    ';min-width: 100px' +
                    ';display: inline-block' +
                    ';text-align: center' +
                    ';text-transform: uppercase' +
                    ';text-decoration: none' +
                    ';padding: 12px 24px' +
                    ';cursor: Pointer' +
                    ';border-radius: 4px'

                return `
                  <table border="0" cellpadding="0" cellspacing="0" class="btn btn-primary" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; box-sizing: border-box;">
                    <tbody>
                      <tr>
                        <td align="center" style="font-family: sans-serif; font-size: 14px; vertical-align: top; padding-bottom: 15px ${aligncoiso}">
                          <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: auto;">
                            <tbody>
                              <tr>
                                <!--[if mso]>
                                  <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="${link}" style="mso-wrap-style:none; mso-position-horizontal: center;v-text-anchor:middle; height: 40px;width:300px;" arcsize="10%" stroke="f" fillcolor="#${color}">
                                    <w:anchorlock/>
                                    <td align="center">
                                      <center style="font-family: sans-serif; font-size: 14px; vertical-align: top; background-color: #${color}; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; text-align: center;">
                                        <a href="${link}" target="_blank" style="display: inline-block; color: ${textColor}; background-color: #${color}; border-radius: 5px; box-sizing: border-box; cursor: pointer; text-decoration: none; font-size: 14px; font-weight: bold; margin: 0; padding: 12px 25px; text-transform: capitalize ${myBorder}">
                                          <span style="color: ${textColor}">${title}</span>
                                        </a>
                                      </center>
                                  </v:roundrect>
                                <![endif]-->
                                  <td style="mso-hide:all;font-family: sans-serif; font-size: 14px; vertical-align: top; background-color: #${color}; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; text-align: center;">
                                    <a href="${link}" target="_blank" style="display: inline-block; color: ${textColor}; background-color: #${color}; border-radius: 5px; box-sizing: border-box; cursor: pointer; text-decoration: none; font-size: 14px; font-weight: bold; margin: 0; padding: 12px 25px; text-transform: capitalize ${myBorder}">
                                      <span style="color: ${textColor}">${title}</span>
                                    </a>
                                <!--[if mso]></td><![endif]-->
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                `

                //return `<div style="text-align:center"><a href="${link}" style="${callStyle}" class="callLink"><span>${title}</span></a></div>`
                }
                const data2 = contentState.getEntity(block.getEntityAt(0)).getData();
                const { link, src, alt, alignment, width } = data2;
                if (link) {
                  //console.log(link)
                }

                const newWidth = width ? "width:" + width + "%" : ""
                var myStyle=""
                switch (alignment) {
                  case 'left':
                    myStyle = 'position:relative;cursor:default;float:left;' + newWidth
                    break;
                  case 'center':
                    myStyle = 'position:relative;cursor:default;margin-left:auto;margin-right:auto;display:block;' + newWidth
                    break;
                  case 'right':
                    myStyle = 'position:relative;cursor:default;float:right;' + newWidth
                  break;
                  default:
                    myStyle = 'position:relative;cursor:default;float:left;' + newWidth
                }
                return `
                  <!--[if mso]>
                    <div style="text-align: ${alignment}">
                      <a href="${link}"><img width="${width}%" class=".img-responsive" style="${myStyle}" src="${src}"></img></a>
                  <![endif]-->
                    <a style="mso-hide:all;" href="${link}"><img width="${width}%" class=".img-responsive" style="${myStyle}" src="${src}"></img></a>
                  <!--[if mso]></div><![endif]-->
              `
              },
            },
          };
          let html = stateToHTML(editorState.getCurrentContent(), options);
          _notifyAppuro(html);
          // console.log(JSON.stringify(
          //   convertToRaw(editorState.getCurrentContent()),
          //   null,
          //   2
          // ))
            // _notifyAppuroEditor(editorState);
          }
          if (prevValue.settings !== settings) {
            setDisabled(false)
            setLoading(false)
          }
        }
      }, [settings, editorState])

    useEffect(() => {
      return () => {
      };
    });

    return (
      <div>
        <SEO title="Newsletter" />
        <Toolbar>
          <h1 style={{ flex: 1 }}>Newsletter</h1>
        </Toolbar>
        {/* Social Links */}
        <div>
          <FormControl className={classes.formroot + " " + classes.margin}>
            <InputLabel shrink htmlFor="twitter-input3">
              {intl.formatMessage({ id: "email.subject" })}
            </InputLabel>
            <MyInput
              disabled={disabled}
              onChange={handleInput}
              value={subject}
              id="subject"
              style={{ width: "100%" }}
            />
          </FormControl>
          <FormControl className={classes.formroot}>
            <InputLabel shrink htmlFor="twitter-input3">
              {intl.formatMessage({ id: "email.preview" })}
            </InputLabel>
            <MyInput
              disabled={disabled}
              onChange={handleInput}
              value={preview}
              id="preview"
              style={{ width: "100%" }}
            />
          </FormControl>
        </div>
        {settings && (
          <div
            style={{
              minWidth: "100%",
              width: "100%",
              backgroundColor: settings ? settings.bgcolor : "#ffffff",
              marginTop: 4,
              borderRadius: 5,
              border: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <table
              width="100%"
              className="Wrapper"
              align="center"
              style={{
                border: 0,
                borderCollapse: "collapse",
                margin: "0 auto !important",
                padding: 0,
                maxWidth: 600,
                minWidth: 600,
                width: 600,
              }}
            >
              <tbody>
                <tr>
                  <td
                    className="Spacerkill"
                    height="44"
                    style={{
                      border: 0,
                      borderCollapse: "collapse",
                      margin: 0,
                      padding: 0,
                      WebkitFontSmoothing: "antialiased",
                      MozOsxFontSmoothing: "grayscale",
                    }}
                  >
                    &nbsp;
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: 0,
                      borderCollapse: "collapse",
                      margin: 0,
                      padding: 0,
                      WebkitFontSmoothing: "antialiased",
                      MozOsxFontSmoothing: "grayscale",
                    }}
                  >
                    <table
                      className="Sectionfirst"
                      width="100%"
                      style={{
                        borderTopLeftRadius: 5,
                        borderTopRightRadius: 5,
                        border: 0,
                        borderCollapse: "collapse",
                        margin: 0,
                        padding: 0,
                        backgroundColor: "#ffffff",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            className="Spacergutter"
                            width="64"
                            style={{
                              border: 0,
                              borderCollapse: "collapse",
                              margin: 0,
                              padding: 0,
                              WebkitFontSmoothing: "antialiased",
                              MozOsxFontSmoothing: "grayscale",
                              color: "#ffffff",
                              fontSize: 1,
                              lineHeight: "1px",
                              msoLineHeightRule: "exactly",
                            }}
                          >
                            &nbsp;
                          </td>
                          <td
                            className="Toplogo"
                            height="62"
                            style={{
                              border: 0,
                              borderCollapse: "collapse",
                              margin: 0,
                              padding: 0,
                              WebkitFontSmoothing: "antialiased",
                              MozOsxFontSmoothing: "grayscale",
                              color: "#ffffff",
                              fontSize: 1,
                              lineHeight: "1px",
                              msoLineHeightRule: "exactly",
                              paddingTop: 50,
                              paddingBottom: 10,
                            }}
                          >
                            <table
                              width="100%"
                              style={{
                                display: "block",
                                border: 0,
                                borderCollapse: "collapse",
                                margin: 0,
                                padding: 0,
                                backgroundColor: "#ffffff",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td
                                    width={settings.logourl ? 30 : 0}
                                    style={{
                                      border: 0,
                                      borderCollapse: "collapse",
                                      margin: 0,
                                      padding: 0,
                                      WebkitFontSmoothing: "antialiased",
                                      MozOsxFontSmoothing: "grayscale",
                                      color: "#ffffff",
                                      fontSize: 1,
                                      lineHeight: "1px",
                                      msoLineHeightRule: "exactly",
                                    }}
                                  >
                                    <div
                                      style={{
                                        backgroundColor: "white",
                                        borderRadius: 30,
                                        boxShadow:
                                          "white 0px 0px 0px 1px rgba(0, 0, 0, 0.15)",
                                        height: 30,
                                        width: settings.logourl ? 30 : 0,
                                        overflow: "hidden",
                                        display: "inline-Block",
                                      }}
                                    >
                                      <a
                                        href=""
                                        onClick={event =>
                                          event.preventDefault()
                                        }
                                        style={{
                                          WebkitFontSmoothing: "antialiased",
                                          MozOsxFontSmoothing: "grayscale",
                                          outline: 0,
                                          textDecoration: "none",
                                          color: "#6d7886",
                                        }}
                                      >
                                        <img
                                          alt="Appuro"
                                          height="30px"
                                          width="30px"
                                          src={
                                            settings.logourl
                                              ? settings.logourl
                                              : "https://d1vhh2lpbtr7th.cloudfront.net/images/pixel.png"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </td>
                                  <td
                                    width="auto"
                                    align="left"
                                    style={{
                                      border: 0,
                                      borderCollapse: "collapse",
                                      margin: 0,
                                      padding: 0,
                                      WebkitFontSmoothing: "antialiased",
                                      MozOsxFontSmoothing: "grayscale",
                                      width: "auto",
                                      fontFamily:
                                        "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif",
                                      msoLineHeightRule: "exactly",
                                      verticalSlign: "middle",
                                      color: "#8898aa",
                                      fontSize: 15,
                                      lineHeight: "100%",
                                      textAlign: "left",
                                    }}
                                  >
                                    <a
                                      href=""
                                      onClick={event => event.preventDefault()}
                                      style={{
                                        paddingLeft: 10,
                                        WebkitFontSmoothing: "antialiased",
                                        MozOsxFontSmoothing: "grayscale",
                                        fontFamily:
                                          "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif",
                                        msoLineHeightRule: "exactly",
                                        color: "#525f7f",
                                        fontSize: 15,
                                        outline: 0,
                                        textDecoration: "none",
                                        lineHeight: "100%",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {settings ? settings.title : ""}
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td
                            className="Spacergutter"
                            width="64"
                            style={{
                              border: 0,
                              borderCollapse: "collapse",
                              margin: 0,
                              padding: 0,
                              WebkitFontSmoothing: "antialiased",
                              MozOsxFontSmoothing: "grayscale",
                              color: "#ffffff",
                              fontSize: 1,
                              lineHeight: "1px",
                              msoLineHeightRule: "exactly",
                            }}
                          >
                            &nbsp;
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* Separator */}
                    <table
                      className="Section Separator"
                      width="100%"
                      style={{
                        border: 0,
                        borderCollapse: "collapse",
                        margin: 0,
                        padding: 0,
                        backgroundColor: "#ffffff",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            className="Spacer Spacergutter"
                            width="64"
                            style={{
                              border: 0,
                              borderCollapse: "collapse",
                              margin: 0,
                              padding: 0,
                              WebkitFontSmoothing: "antialiased",
                              MozOsxFontSmoothing: "grayscale",
                              color: "#ffffff",
                              fontSize: 1,
                              lineHeight: "1px",
                              msoLineHeightRule: "exactly",
                            }}
                          >
                            &nbsp;
                          </td>
                          <td
                            className="Spacer"
                            bgcolor="e6ebf1"
                            height="1"
                            style={{
                              border: 0,
                              borderCollapse: "collapse",
                              margin: 0,
                              padding: 0,
                              WebkitFontSmoothing: "antialiased",
                              MozOsxFontSmoothing: "grayscale",
                              color: "#ffffff",
                              fontSize: 1,
                              lineHeight: "1px",
                              msoLineHeightRule: "exactly",
                            }}
                          >
                            &nbsp;
                          </td>
                          <td
                            className="Spacer Spacergutter"
                            width="64"
                            style={{
                              border: 0,
                              borderCollapse: "collapse",
                              margin: 0,
                              padding: 0,
                              WebkitFontSmoothing: "antialiased",
                              MozOsxFontSmoothing: "grayscale",
                              color: "#ffffff",
                              fontSize: 1,
                              lineHeight: "1px",
                              msoLineHeightRule: "exactly",
                            }}
                          >
                            &nbsp;
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* Content Space */}
                    <table
                      width="100%"
                      style={{
                        border: 0,
                        borderCollapse: "collapse",
                        margin: 0,
                        padding: 0,
                        backgroundColor: "#ffffff",
                        verticalAlign: "middle",
                        color: "#525f7f!important",
                        fontSize: 16,
                        lineHeight: "24px",
                        fontFamily:
                          "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,RobotoDraft,Helvetica Neue,Arial,Ubuntu,sans-serif",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            className="Spacergutter"
                            width="64"
                            style={{
                              border: 0,
                              borderCollapse: "collapse",
                              margin: 0,
                              padding: 0,
                              WebkitFontSmoothing: "antialiased",
                              MozOsxFontSmoothing: "grayscale",
                              color: "#ffffff",
                              fontSize: 1,
                              lineHeight: "1px",
                              msoLineHeightRule: "exactly",
                            }}
                          >
                            &nbsp;
                          </td>
                          <td
                            style={{
                              border: 0,
                              borderCollapse: "collapse",
                              margin: 0,
                              paddingTop: 15,
                              paddingBottom: 15,
                              WebkitFontSmoothing: "antialiased",
                              MozOsxFontSmoothing: "grayscale",
                              fontFamily:
                                "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif",
                              msoLineHeightRule: "exactly",
                              verticalAlign: "middle",
                              color: "#525f7f!important",
                              fontSize: 15,
                              lineHeight: "24px",
                            }}
                          >
                            <div
                              className={editorStyles.editor}
                              onClick={focusEditor}
                            >
                              <Editor
                                editorState={editorState}
                                handleKeyCommand={_handleKeyCommand}
                                keyBindingFn={_mapKeyToEditorCommand}
                                onChange={onChange}
                                placeholder={intl.formatMessage({
                                  id: "email.tell",
                                })}
                                plugins={plugins}
                                decorators={[CallToActionDecorator]}
                                ref={element => {
                                  editor = element
                                }}
                              />
                              <AlignmentTool />
                              <InlineToolbar>
                                {externalProps => (
                                  <React.Fragment>
                                    <BoldButton {...externalProps} />
                                    <ItalicButton {...externalProps} />
                                    <UnderlineButton {...externalProps} />
                                    <linkPlugin.LinkButton {...externalProps} />
                                    <HeadlineOneButton {...externalProps} />
                                    <HeadlineTwoButton {...externalProps} />
                                    <UnorderedListButton {...externalProps} />
                                    <OrderedListButton {...externalProps} />
                                  </React.Fragment>
                                )}
                              </InlineToolbar>
                            </div>
                          </td>
                          <td
                            className="Spacergutter"
                            width="64"
                            style={{
                              border: 0,
                              borderCollapse: "collapse",
                              margin: 0,
                              padding: 0,
                              WebkitFontSmoothing: "antialiased",
                              MozOsxFontSmoothing: "grayscale",
                              color: "#ffffff",
                              fontSize: 1,
                              lineHeight: "1px",
                              msoLineHeightRule: "exactly",
                            }}
                          >
                            &nbsp;
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      width="100%"
                      style={{
                        border: "none",
                        borderCollapse: "collapse",
                        margin: 0,
                        padding: 0,
                        backgroundColor: "#ffffff",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td></td>
                          <td>
                            <div
                              style={{
                                borderRadius: "3px",
                                margin: "10px 64px",
                                overflow: "hidden",
                                boxShadow:
                                  "0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2)",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  color: "none",
                                  border: "none",
                                  whiteSpace: "nowrap",
                                  width: "cals(100% - 64px)",
                                }}
                              >
                                <ImageAdd
                                  editorState={editorState}
                                  onChange={onChange}
                                  modifier={addImage}
                                  emailid={emailId}
                                />
                                <ActionAdd
                                  editorState={editorState}
                                  onChange={onChange}
                                  modifier={addAction}
                                />
                                <Tooltip
                                  title={intl.formatMessage({
                                    id: "dom.delete",
                                  })}
                                >
                                  <Button
                                    style={{
                                      minWidth: 36,
                                      borderRadius: 0,
                                      padding: 0,
                                      color: "rgb(136, 136, 136)",
                                    }}
                                    onClick={handleDelete}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </Tooltip>
                                <Tooltip
                                  title={intl.formatMessage({
                                    id: "email.scheduling",
                                  })}
                                >
                                  <Button
                                    style={{ marginLeft: "auto" }}
                                    className={
                                      !emailDateTime
                                        ? classes.bottomBar
                                        : classes.bottomBar +
                                          " " +
                                          classes.scheduled
                                    }
                                    onClick={handleClickDialogOpen}
                                    aria-label="Schedule"
                                    disableRipple
                                  >
                                    <ScheduleIcon />
                                  </Button>
                                </Tooltip>
                                <Tooltip
                                  title={intl.formatMessage({
                                    id: "email.send",
                                  })}
                                >
                                  <Button
                                    onClick={handleSendMail}
                                    alt="ya"
                                    style={{
                                      borderRadius: 0,
                                      color: "rgb(136, 136, 136)",
                                    }}
                                  >
                                    <SendIcon />
                                  </Button>
                                </Tooltip>
                              </div>
                            </div>
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                    {/* Vertical Space 20 */}
                    <table
                      width="100%"
                      style={{
                        border: 0,
                        borderCollapse: "collapse",
                        margin: 0,
                        padding: 0,
                        backgroundColor: "#f8f6fc",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            height="20"
                            style={{
                              border: 0,
                              borderCollapse: "collapse",
                              margin: 0,
                              padding: 0,
                              WebkitFontSmoothing: "antialiased",
                              MozOsxFontSmoothing: "grayscale",
                              color: "#ffffff",
                              fontSize: 1,
                              lineHeight: "1px",
                              msoLineHeightRule: "exactly",
                            }}
                          >
                            &nbsp;
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer */}
                    <table
                      width="100%"
                      style={{
                        border: 0,
                        borderCollapse: "collapse",
                        margin: 0,
                        padding: 0,
                        backgroundColor: "#f8f6fc",
                        WebkitFontSmoothing: "antialiased",
                        MozOsxFontSmoothing: "grayscale",
                        fontFamily:
                          "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif",
                        msoLineHeightRule: "exactly",
                        verticalAlign: "middle",
                        color: "#6d7886!important",
                        fontSize: 12,
                        lineHeight: "16px",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            className="Spacergutter"
                            width="64"
                            style={{
                              border: 0,
                              borderCollapse: "collapse",
                              margin: 0,
                              padding: 0,
                              WebkitFontSmoothing: "antialiased",
                              MozOsxFontSmoothing: "grayscale",
                              color: "#ffffff",
                              fontSize: 1,
                              lineHeight: "1px",
                              msoLineHeightRule: "exactly",
                            }}
                          >
                            &nbsp;
                          </td>
                          <td
                            style={{
                              border: 0,
                              borderCollapse: "collapse",
                              margin: 0,
                              padding: 0,
                              WebkitFontSmoothing: "antialiased",
                              MozOsxFontSmoothing: "grayscale",
                              fontFamily:
                                "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif",
                              msoLineHeightRule: "exactly",
                              verticalAlign: "middle",
                              color: "#6d7886",
                              fontSize: 12,
                              lineHeight: "16px",
                            }}
                          >
                            {intl.formatMessage({ id: "email.ifyouprefer" })}{" "}
                            <a
                              onClick={event => event.preventDefault()}
                              href=""
                              style={{
                                border: 0,
                                margin: 0,
                                padding: 0,
                                color: "#008cdd",
                                fontFamily:
                                  "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif",
                                textDecoration: "none",
                              }}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span
                                style={{
                                  border: 0,
                                  margin: 0,
                                  padding: 0,
                                  color: "#008cdd",
                                  textDecoration: "none",
                                }}
                              >
                                {intl.formatMessage({
                                  id: "email.youcanunsub",
                                })}
                                .
                              </span>
                            </a>
                          </td>
                          <td
                            className="Spacergutter"
                            width="64"
                            style={{
                              border: 0,
                              borderCollapse: "collapse",
                              margin: 0,
                              padding: 0,
                              WebkitFontSmoothing: "antialiased",
                              MozOsxFontSmoothing: "grayscale",
                              color: "#ffffff",
                              fontSize: 1,
                              lineHeight: "1px",
                              msoLineHeightRule: "exactly",
                            }}
                          >
                            &nbsp;
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* Vertical Space */}
                    <table
                      width="100%"
                      style={{
                        border: 0,
                        borderCollapse: "collapse",
                        margin: 0,
                        padding: 0,
                        backgroundColor: "#f8f6fc",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            height="20"
                            style={{
                              border: 0,
                              borderCollapse: "collapse",
                              margin: 0,
                              padding: 0,
                              WebkitFontSmoothing: "antialiased",
                              MozOsxFontSmoothing: "grayscale",
                              color: "#ffffff",
                              fontSize: 1,
                              lineHeight: "1px",
                              msoLineHeightRule: "exactly",
                            }}
                          >
                            &nbsp;
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer content */}
                    <table
                      width="100%"
                      style={{
                        border: 0,
                        borderCollapse: "collapse",
                        margin: 0,
                        padding: 0,
                        backgroundColor: "#f8f6fc",
                        WebkitFontSmoothing: "antialiased",
                        MozOsxFontSmoothing: "grayscale",
                        fontFamily:
                          "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif",
                        msoLineHeightRule: "exactly",
                        verticalAlign: "middle",
                        color: "#6d7886!important",
                        fontSize: 12,
                        lineHeight: "16px",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            className="Spacergutter"
                            width="64"
                            style={{
                              border: 0,
                              borderCollapse: "collapse",
                              margin: 0,
                              padding: 0,
                              WebkitFontSmoothing: "antialiased",
                              MozOsxFontSmoothing: "grayscale",
                              color: "#ffffff",
                              fontSize: 1,
                              lineHeight: "1px",
                              msoLineHeightRule: "exactly",
                            }}
                          >
                            &nbsp;
                          </td>
                          <td
                            style={{
                              border: 0,
                              borderCollapse: "collapse",
                              margin: 0,
                              padding: 0,
                              WebkitFontSmoothing: "antialiased",
                              MozOsxFontSmoothing: "grayscale",
                              fontFamily:
                                "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif",
                              msoLineHeightRule: "exactly",
                              verticalAlign: "middle",
                              color: "#6d7886",
                              fontSize: 12,
                              lineHeight: "16px",
                            }}
                          >
                            {settings &&
                                (settings.general.business_name ? settings.general.business_name + ", " : "" +
                                settings.general.address_line1 ? settings.general.address_line1 + ", " : "" +
                                settings.general.address_line2 ? settings.general.address_line2 + ", " : "" +
                                settings.general.cpostal ? settings.general.cpostal + ", " : "" +
                                settings.general.city ? settings.general.city : "").replace(/,(\s+)?$/, '')
                                //+
                                // settings.general.country
                              }
                          </td>
                          <td
                            className="Spacergutter"
                            width="64"
                            style={{
                              border: 0,
                              borderCollapse: "collapse",
                              margin: 0,
                              padding: 0,
                              WebkitFontSmoothing: "antialiased",
                              MozOsxFontSmoothing: "grayscale",
                              color: "#ffffff",
                              fontSize: 1,
                              lineHeight: "1px",
                              msoLineHeightRule: "exactly",
                            }}
                          >
                            &nbsp;
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* Vertical Space */}
                    <table
                      width="100%"
                      style={{
                        border: 0,
                        borderCollapse: "collapse",
                        margin: 0,
                        padding: 0,
                        backgroundColor: "#f8f6fc",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            height="20"
                            style={{
                              border: 0,
                              borderCollapse: "collapse",
                              margin: 0,
                              padding: 0,
                              WebkitFontSmoothing: "antialiased",
                              MozOsxFontSmoothing: "grayscale",
                              color: "#ffffff",
                              fontSize: 1,
                              lineHeight: "1px",
                              msoLineHeightRule: "exactly",
                            }}
                          >
                            &nbsp;
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer links */}
                    <table
                      width="100%"
                      style={{
                        border: 0,
                        borderCollapse: "collapse",
                        margin: 0,
                        padding: 0,
                        backgroundColor: "#f8f6fc",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            className="Spacergutter"
                            width="64"
                            style={{
                              border: 0,
                              borderCollapse: "collapse",
                              margin: 0,
                              padding: 0,
                              WebkitFontSmoothing: "antialiased",
                              MozOsxFontSmoothing: "grayscale",
                              color: "#ffffff",
                              fontSize: 1,
                              lineHeight: "1px",
                              msoLineHeightRule: "exactly",
                            }}
                          >
                            &nbsp;
                          </td>
                          <td
                            style={{
                              border: 0,
                              borderCollapse: "collapse",
                              margin: 0,
                              padding: 0,
                              WebkitFontSmoothing: "antialiased",
                              MozOsxFontSmoothing: "grayscale",
                              fontFamily:
                                "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif",
                              msoLineHeightRule: "exactly",
                              verticalAlign: "middle",
                              color: "#8898aa",
                              fontSize: 12,
                              lineHeight: "16px",
                            }}
                          >
                            <table
                              width="100%"
                              style={{
                                border: 0,
                                borderCollapse: "collapse",
                                margin: 0,
                                padding: 0,
                                backgroundColor: "#f8f6fc",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      border: 0,
                                      borderCollapse: "collapse",
                                      margin: 0,
                                      padding: 0,
                                      WebkitFontSmoothing: "antialiased",
                                      MozOsxFontSmoothing: "grayscale",
                                      fontFamily:
                                        "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif",
                                      msoLineHeightRule: "exactly",
                                      verticalAlign: "middle",
                                      fontSize: 15,
                                      fontWeight: 700,
                                      lineHeight: "16px",
                                    }}
                                  >
                                    {!settings.branding && (
                                      <React.Fragment>
                                        <a
                                          onClick={event =>
                                            event.preventDefault()
                                          }
                                          href=""
                                          style={{
                                            WebkitFontSmoothing: "antialiased",
                                            MozOsxFontSmoothing: "grayscale",
                                            outline: 0,
                                            textDecoration: "none",
                                            color: "#6d7886",
                                          }}
                                        >
                                          <img
                                            alt=""
                                            height="48"
                                            width="128"
                                            src={
                                              "https://d1vhh2lpbtr7th.cloudfront.net/images/acroleads-" +
                                              intl.locale +
                                              ".png"
                                            }
                                            style={{
                                              lineHeight: "100%",
                                              borderWidth: 0,
                                              verticalAlign: "top",
                                            }}
                                          />
                                        </a>
                                        <IconButton
                                          className={classes.brandingbutton}
                                          onClick={handleClick}
                                          aria-label="delete"
                                          disableRipple
                                        >
                                          <AnnouncementIcon />
                                        </IconButton>
                                        <Popover
                                          classes={{
                                            root: classes.poproot,
                                            paper: classes.poppaper,
                                          }}
                                          id={id}
                                          open={open}
                                          anchorEl={anchorEl}
                                          onClose={handleClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center",
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "center",
                                          }}
                                        >
                                          <div style={{ padding: 20 }}>
                                            <Typography
                                              style={{ textAlign: "left" }}
                                              variant="caption"
                                              component="p"
                                            >
                                              {intl.formatMessage({
                                                id: "email.remove",
                                              })}
                                            </Typography>
                                          </div>
                                          <div
                                            style={{
                                              padding: 12,
                                              background: "#e5f1ff",
                                            }}
                                          >
                                            <MyButton
                                              onClick={() =>
                                                navigate("/app/account")
                                              }
                                              color="primary"
                                            >
                                              {intl.formatMessage({
                                                id: "email.enablebranding",
                                              })}
                                            </MyButton>
                                          </div>
                                        </Popover>
                                      </React.Fragment>
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      border: 0,
                                      borderCollapse: "collapse",
                                      margin: 0,
                                      padding: 0,
                                      WebkitFontSmoothing: "antialiased",
                                      MozOsxFontSmoothing: "grayscale",
                                      fontFamily:
                                        "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif",
                                      msoLineHeightRule: "exactly",
                                      verticalAlign: "middle",
                                      color: "#8898aa",
                                      fontSize: 12,
                                      lineHeight: "16px",
                                      textAlign: "right",
                                    }}
                                  >
                                    <table
                                      style={{
                                        border: 0,
                                        borderCollapse: "collapse",
                                        margin: 0,
                                        padding: 0,
                                        backgroundColor: "#f8f6fc",
                                        width: "100%",
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          {settings &&
                                          settings.social.facebook ? (
                                            <td
                                              width="auto"
                                              align="right"
                                              style={{
                                                border: 0,
                                                borderCollapse: "collapse",
                                                margin: 0,
                                                padding: 0,
                                                WebkitFontSmoothing:
                                                  "antialiased",
                                                MozOsxFontSmoothing:
                                                  "grayscale",
                                                width: "auto",
                                                fontFamily:
                                                  "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif",
                                                msoLineHeightRule: "exactly",
                                                verticalAlign: "middle",
                                                color: "#8898aa",
                                                fontSize: 15,
                                                fontWeight: 700,
                                                lineHeight: "16px",
                                              }}
                                            >
                                              <a
                                                href={
                                                  settings
                                                    ? settings.social.facebook
                                                    : "#"
                                                }
                                                style={{
                                                  WebkitFontSmoothing:
                                                    "antialiased",
                                                  MozOsxFontSmoothing:
                                                    "grayscale",
                                                  outline: 0,
                                                  textDecoration: "none",
                                                  color: "#6d7886",
                                                }}
                                              >
                                                <img
                                                  alt=""
                                                  height="20"
                                                  width="20"
                                                  src="https://d1vhh2lpbtr7th.cloudfront.net/images/facebook.png"
                                                  style={{
                                                    lineHeight: "100%",
                                                    borderWidth: 0,
                                                    verticalAlign: "top",
                                                  }}
                                                />
                                              </a>
                                            </td>
                                          ) : null}
                                          {settings &&
                                          settings.social.instagram ? (
                                            <td
                                              width="20"
                                              align="right"
                                              style={{
                                                border: 0,
                                                borderCollapse: "collapse",
                                                margin: 0,
                                                padding: 0,
                                                WebkitFontSmoothing:
                                                  "antialiased",
                                                MozOsxFontSmoothing:
                                                  "grayscale",
                                                width: 20,
                                                fontFamily:
                                                  "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif",
                                                msoLineHeightRule: "exactly",
                                                verticalAlign: "middle",
                                                color: "#8898aa",
                                                fontSize: 12,
                                                lineHeight: "16px",
                                                textAlign: "right",
                                              }}
                                            >
                                              <a
                                                href={
                                                  settings
                                                    ? settings.social.instagram
                                                    : "#"
                                                }
                                                style={{
                                                  WebkitFontSmoothing:
                                                    "antialiased",
                                                  MozOsxFontSmoothing:
                                                    "grayscale",
                                                  outline: 0,
                                                  textDecoration: "none",
                                                  color: "#6d7886",
                                                }}
                                              >
                                                <img
                                                  alt=""
                                                  height="20"
                                                  width="20"
                                                  src="https://d1vhh2lpbtr7th.cloudfront.net/images/instagram.png"
                                                  style={{
                                                    lineHeight: "100%",
                                                    borderWidth: 0,
                                                    verticalAlign: "top",
                                                  }}
                                                />
                                              </a>
                                            </td>
                                          ) : null}
                                          {settings &&
                                          settings.social.twitter ? (
                                            <td
                                              width="20"
                                              align="right"
                                              style={{
                                                border: 0,
                                                borderCollapse: "collapse",
                                                margin: 0,
                                                padding: 0,
                                                WebkitFontSmoothing:
                                                  "antialiased",
                                                MozOsxFontSmoothing:
                                                  "grayscale",
                                                width: 20,
                                                fontFamily:
                                                  "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif",
                                                msoLineHeightRule: "exactly",
                                                verticalAlign: "middle",
                                                color: "#8898aa",
                                                fontSize: 12,
                                                lineHeight: "16px",
                                                textAlign: "right",
                                              }}
                                            >
                                              <a
                                                href={
                                                  settings
                                                    ? settings.social.twitter
                                                    : "#"
                                                }
                                                style={{
                                                  WebkitFontSmoothing:
                                                    "antialiased",
                                                  MozOsxFontSmoothing:
                                                    "grayscale",
                                                  outline: 0,
                                                  textDecoration: "none",
                                                  color: "#6d7886",
                                                }}
                                              >
                                                <img
                                                  alt=""
                                                  height="20"
                                                  width="20"
                                                  src="https://d1vhh2lpbtr7th.cloudfront.net/images/twitter.png"
                                                  style={{
                                                    lineHeight: "100%",
                                                    borderWidth: 0,
                                                    verticalAlign: "top",
                                                  }}
                                                />
                                              </a>
                                            </td>
                                          ) : null}
                                          {settings &&
                                          settings.social.medium ? (
                                            <td
                                              width="20"
                                              align="right"
                                              style={{
                                                border: 0,
                                                borderCollapse: "collapse",
                                                margin: 0,
                                                padding: 0,
                                                WebkitFontSmoothing:
                                                  "antialiased",
                                                MozOsxFontSmoothing:
                                                  "grayscale",
                                                width: 20,
                                                fontFamily:
                                                  "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif",
                                                msoLineHeightRule: "exactly",
                                                verticalAlign: "middle",
                                                color: "#8898aa",
                                                fontSize: 12,
                                                lineHeight: "16px",
                                                textAlign: "right",
                                              }}
                                            >
                                              <a
                                                href={
                                                  settings
                                                    ? settings.social.medium
                                                    : "#"
                                                }
                                                style={{
                                                  WebkitFontSmoothing:
                                                    "antialiased",
                                                  MozOsxFontSmoothing:
                                                    "grayscale",
                                                  outline: 0,
                                                  textDecoration: "none",
                                                  color: "#6d7886",
                                                }}
                                              >
                                                <img
                                                  alt=""
                                                  height="20"
                                                  width="20"
                                                  src="https://d1vhh2lpbtr7th.cloudfront.net/images/medium.png"
                                                  style={{
                                                    lineHeight: "100%",
                                                    borderWidth: 0,
                                                    verticalAlign: "top",
                                                  }}
                                                />
                                              </a>
                                            </td>
                                          ) : null}
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td
                            className="Spacergutter"
                            width="64"
                            style={{
                              border: 0,
                              borderCollapse: "collapse",
                              margin: 0,
                              padding: 0,
                              WebkitFontSmoothing: "antialiased",
                              MozOsxFontSmoothing: "grayscale",
                              color: "#ffffff",
                              fontSize: 1,
                              lineHeight: "1px",
                              msoLineHeightRule: "exactly",
                            }}
                          >
                            &nbsp;
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer bottom */}
                    <table
                      className="Sectionlast"
                      width="100%"
                      style={{
                        border: 0,
                        borderCollapse: "collapse",
                        margin: 0,
                        padding: 0,
                        backgroundColor: "#f8f6fc",
                        borderBottomLeftRadius: 5,
                        borderBottomRightRadius: 5,
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            height="64"
                            style={{
                              border: 0,
                              borderCollapse: "collapse",
                              margin: 0,
                              padding: 0,
                              WebkitFontSmoothing: "antialiased",
                              MozOsxFontSmoothing: "grayscale",
                              color: "#ffffff",
                              fontSize: 1,
                              lineHeight: "1px",
                              msoLineHeightRule: "exactly",
                            }}
                          >
                            &nbsp;
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td
                    className="Spacerkill"
                    height="44"
                    style={{
                      border: 0,
                      borderCollapse: "collapse",
                      margin: 0,
                      padding: 0,
                      WebkitFontSmoothing: "antialiased",
                      MozOsxFontSmoothing: "grayscale",
                    }}
                  >
                    &nbsp;
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {loading && (
          <div className={classes.baseloading}>
            <div
              style={{
                paddingLeft: "240px",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <RotateLoader
                css={classes.override}
                sizeUnit={"px"}
                size={15}
                color={"#f5005780"}
                loading={loading}
              />
            </div>
          </div>
        )}
        <Dialog
          style={{ height: 520 }}
          open={openDialog}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {intl.formatMessage({ id: "email.schedulenow" })}
          </DialogTitle>
          <FormControlLabel
            style={{ flex: "0 0 auto", margin: 0, padding: "0px 24px" }}
            value="schedule"
            control={
              <Checkbox
                onChange={handleNow}
                checked={schedule}
                color="primary"
              />
            }
            label={intl.formatMessage({ id: "email.scheduled" })}
            labelPlacement="end"
          />
          <DialogContent classes={{ root: classes.pickerroot }}>
            <DatePicker
              locale={intl.locale}
              open={true}
              selected={startDate}
              onChange={date => setStartDate(date)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption={intl.formatMessage({ id: "email.time" })}
              minDate={new Date()}
              dateFormat="MMMM d, yyyy h:mm aa"
              className={classes.pickerroot2}
              popperClassName={classes.popperclass}
              popperModifiers={{
                flip: { behavior: ["bottom"] },
                preventOverflow: { enabled: false },
                hide: { enabled: true },
                preventOverflow: { boundariesElement: "window" },
              }}
              selected={startDate}
              onChange={date => setStartDate(date)}
            />
          </DialogContent>
          <DialogActions
            style={{ padding: "12px 20px", background: "rgb(229, 241, 255)" }}
          >
            <MyButton onClick={handleDialogAgree} color="primary" autoFocus>
              {intl.formatMessage({ id: "acc.confirm" })}
            </MyButton>
          </DialogActions>
        </Dialog>
      </div>
    )
  }