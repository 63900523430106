import React, { Component, useCallback, useRef, useEffect, usePrevious } from "react";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveAlt from '@material-ui/icons/SaveAlt'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase'
import FormControl from '@material-ui/core/FormControl'
import NewContact from './newContact';
import ImportContact from './importContact';
import dateFormat from 'dateformat'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import { connect } from "react-redux";
import { allContacts, deleteContacts, updateContacts, searchContacts } from "../../state/store/actions/contacts";
import { bindActionCreators } from 'redux';
import RotateLoader from 'react-spinners/RotateLoader';
import AlertDialog from '../alertdialog'
import { injectIntl, navigate } from "gatsby-plugin-intl"
import SEO from '../seo'

function createData(refid, name, email, phone, status, cdate, firstname, lastname, lang) {
  return {refid, name, email, phone, status, cdate, firstname, lastname, lang };
}

var rows = [];

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headRows = (intl) => {
  return ([
    { id: 'name', numeric: false, disablePadding: true, label: intl.formatMessage({id: "cont.name"}) },
    { id: 'email', numeric: true, disablePadding: false, label: intl.formatMessage({id: "cont.email"}) },
    { id: 'phone', numeric: true, disablePadding: false, label: intl.formatMessage({id: "cont.phone"}) },
    { id: 'status', numeric: true, disablePadding: false, label: intl.formatMessage({id: "cont.status"}) },
    { id: 'cdate', numeric: true, disablePadding: false, label: intl.formatMessage({id: "cont.creation"}) },
  ])
};

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, intl } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all contacts' }}
          />
        </TableCell>
        {headRows(intl).map(row => (
          <TableCell
            key={row.id}
            align={row.numeric ? 'right' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'inline-flex'
  },
  title: {
    flex: '0 0 auto',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected, mySelected, handlesel, intl } = props;

  const HandleClick = useCallback(
    (selected, handle) => {
      handle(selected, false)
    },
    [],
  );

  const HandleEditClick = useCallback(
    (selected, handle) => {
      handle(selected, true)
    },
    [],
  );

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} {intl.formatMessage({id: "cont.selected"})}
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {intl.formatMessage({id: "cont.contacts"})}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected === 1 ? (
            <Tooltip title="Edit">
              <IconButton onClick={() => HandleEditClick(mySelected, handlesel)} aria-label="edit">
                <EditIcon />
              </IconButton>
            </Tooltip>
        ) : (null)}
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton onClick={() => HandleClick(mySelected, handlesel)} aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : null }
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    border: '1px solid rgba(0, 0, 0, 0.12)'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  baseloading: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, .70)'
  },
  override: {
    display: 'block',
    margin: 'auto auto',
    borderColor: 'red',
  },
}));

export function EnhancedTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const {deleteselected, updateselected, searching, intl} = props;

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.refid);
      props.handleselected(newSelecteds)
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
    props.handleselected([])
  }

  function handleClick(event, refid) {
    const selectedIndex = selected.indexOf(refid);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, refid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    props.handleselected(newSelected)
    setSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

    function handlesel(valor, editing) {
      if (editing) {
        props.handleupdate()
      } else {
        props.handledelete()
      }
    }

    function usePrevious(value) {
      const ref = useRef();
      useEffect(() => { ref.current = value });
      return ref.current;
    }

    const prevValue = usePrevious({deleteselected, updateselected, searching});
    useEffect(() => {
      if (prevValue !== deleteselected && deleteselected === true) {
        setSelected([])
        setPage(0);
      }
      if (prevValue !== updateselected && updateselected === true) {
        setSelected([])
      }
      if (prevValue !== searching && searching === true) {
        setPage(0)
      }
    }, [deleteselected, updateselected, searching])

  const isSelected = refid => selected.indexOf(refid) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <EnhancedTableToolbar intl={intl} numSelected={selected.length} mySelected={selected} handlesel={handlesel}/>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'small'}
          >
            <EnhancedTableHead
              intl={intl}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.refid);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={event => handleClick(event, row.refid)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.refid}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.email}</TableCell>
                      <TableCell align="right">{row.phone}</TableCell>
                      <TableCell align="right">{row.status}</TableCell>
                      <TableCell align="right">{row.cdate}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {props.loading && (
        <div className={classes.baseloading}>
          <div
            style={{
              paddingLeft: "240px",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RotateLoader
              css={classes.override}
              sizeUnit={"px"}
              size={15}
              color={"#f5005780"}
              loading={props.loading}
            />
          </div>
        </div>
      )}

    </div>
  );
}

const stylesContacts = (theme) => ({
      margin: {
          margin: 2,
      },
      secGroup: {
        marginLeft: 'auto'
      },
      menuButton: {
        marginRight: 18
      },
      savealt: {
        border: '1px solid rgba(245, 0, 87, 0.5)',
        padding: 5
      },
      iconButton: {
        position: 'absolute',
        right: 0,
        padding: 6,
        color: '#f50057', 
        borderRadius: '0 4px 4px 0',
        '&:disabled': {
          color: '#f50057',
        },
        '&:hover': {
          order: '1px solid #f50057',
          backgroundColor: 'rgba(245, 0, 87, 0.08)'
        }
      }
  });

  const BootstrapInput = withStyles(theme => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
        fontSize: '18px',
        color: '#333333'
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid rgba(245, 0, 87, 0.5);',
      fontSize: '0.948rem',
      width: '100%',
      padding: '8px 36px 8px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderColor: '#f50057',
        borderRadius: 4,
      },
    },
  }))(InputBase);

 class Contacts extends Component {
   constructor(props) {
     super(props);
     this.state = {
      openNew: false,
      openImport: false,
      openOld: {},
      alllist: null,
      firstTime: true,
      loading: true,
      selected: [],
      openalert: false,
      searchvalue: '',
      deleteselected: false,
      updateselected: false,
      searching: false
     }
     this.toggleDrawer = this.toggleDrawer.bind(this)
     this.toggleDrawerImport = this.toggleDrawerImport.bind(this)
     this.sendOpen = this.sendOpen.bind(this)
     this.sendOpenImport = this.sendOpenImport.bind(this)
     this.handleSearch = this.handleSearch.bind(this)
     this.handleSearchClose = this.handleSearchClose.bind(this)
     this.handleDelete = this.handleDelete.bind(this)
     this.handleUpdate = this.handleUpdate.bind(this)
     this.handleSelected = this.handleSelected.bind(this)
   }

  toggleDrawer = (openNew) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({openOld: {}})
    this.setState({openNew})
  };

  sendOpen = (valor, close) => {
    if (!valor && close) {
        this.setState({loading: true})
        this.props.allContacts()
    }
    this.setState({openNew: valor})
  }

  toggleDrawerImport = (openImport) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({openImport})
  };

  sendOpenImport = (valor, close) => {
    if (!valor && close) {
        this.setState({loading: true})
        this.props.allContacts()
    }
    this.setState({ openImport: valor })
  }

  handleSearch(event) {
    console.log("1")
    this.setState({searching: true})
    this.setState({searchvalue: event.target.value}, () => {
      this.setState({loading: true})
      this.props.searchContacts(this.state.searchvalue)
    })
  }

  handleSearchClose() {
    console.log("2")
    if (this.state.searchvalue) {
      this.setState({searchvalue: ''})
      this.setState({loading: true})
      this.props.allContacts()
    }
  }

  handleSelected = (sel) => {
    this.setState({selected: sel})
  }

  handleDelete = () => (event) => {
    this.setState({openalert: true})
  }

  dialogCallback = (resp) => {
    if (resp === this.props.intl.formatMessage({
        id: "cont.confirm"
      })) {
      const todelete = this.state.selected
      this.props.deleteContacts(todelete)
      this.setState({loading: true})
      this.setState({deleteselected: true}, () => {
        this.setState({deleteselected: false})
      })
      this.setState()
    }
    this.setState({openalert: false})
  }

  handleUpdate = () => (event) => {
    const toupdate = this.state.selected
    rows.map(b => {
      if (toupdate == b['refid']) {
        this.setState({openOld: b})
        this.setState({openNew: true})
      }
    })
  }

  authUser() {
   return new Promise(function (resolve, reject) {
      firebase.auth().onAuthStateChanged(function(user) {
         if (user) {
            resolve(user);
         } else {
            reject('User not logged in');
            var gu = localStorage.getItem("gatsbyUser");
            if (gu) {
              localStorage.setItem("gatsbyUser", JSON.stringify({}));
            }
            navigate("/app/login")
         }             
      });
   });
}

  componentDidMount() {
    this.setState({ loading: true})
    this.authUser().then((user) => {
      this.props.allContacts()
      }, (e) => {
        console.log(e);
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.delresult !== this.props.delresult) {
      if (typeof this.props.delresult !== undefined) {
        if (this.props.delresult['deleted']) {
          this.setState({selected: []})
        }
        if (this.props.delresult['update'] === "success") {
          this.setState({updateselected: true}, () => {
            this.setState({updateselected: false})
          })
        }
      }
    }
    if (prevProps.all !== this.props.all) {
      this.setState({loading: false})
    }
    if (this.state.alllist !== this.props.all) {
      this.setState({ alllist: this.props.all})
      return
    }
  }
  
  render() {
    const { classes, all, intl } = this.props

    if (all !== this.state.alllist) {
      rows = []
      if (all) {
        all.map(v => {
          rows.push(createData(
            v.contdocid,
            (v.name).trim(),
            v.email,
            v.phone,
            v.status,
            dateFormat(Date(v.creation), "dd mmm yyyy"),
            v.firstname,
            v.lastname,
            v.lang
          ))
        })
      }
    }

    return (
        <React.Fragment>
            <div>
                <SEO title={intl.formatMessage({id: "cont.contacts"})} />
                <Toolbar>
                  <h1 style={{flex: 1}}>{intl.formatMessage({id: "cont.contacts"})}</h1>
                  <div className={classes.secGroup}>
                    <FormControl className={classes.menuButton}>
                      <BootstrapInput
                        placeholder={intl.formatMessage({id: "cont.search"})}
                        disabled={this.state.save}
                        onChange={this.handleSearch}
                        style={{width: '100%'}}
                        value={this.state.searchvalue}
                        id="searchcontact" />
                      <IconButton
                        disabled={!this.state.searchvalue}
                        onClick={this.handleSearchClose}
                        className={classes.iconButton}
                        aria-label="search">
                          {this.state.searchvalue ? <CloseIcon /> : <SearchIcon />}
                      </IconButton>
                    </FormControl>
                    <IconButton onClick={this.toggleDrawerImport(true)} classes={{ root: classes.savealt}} color="secondary" aria-label="Import" className={classes.menuButton}>
                      <SaveAlt />
                    </IconButton>
                    <Button onClick={this.toggleDrawer(true)} variant="outlined" color="secondary" className={classes.menuButton}>
                        {intl.formatMessage({id: "cont.create"})}
                    </Button>
                  </div>
                </Toolbar>
                <EnhancedTable
                  handleselected={this.handleSelected.bind(this)}
                  handledelete={this.handleDelete()}
                  handleupdate={this.handleUpdate()}
                  loading={this.state.loading}
                  deleteselected={this.state.deleteselected}
                  updateselected={this.state.updateselected}
                  searching={this.state.searching}
                  intl={intl}
                />
                <NewContact intl={intl} open={this.state.openNew} old={this.state.openOld} sendopen={this.sendOpen.bind(this)}/>
                <ImportContact intl={intl} open={this.state.openImport} sendopenimport={this.sendOpenImport.bind(this)}/>
                <AlertDialog
                  open={this.state.openalert}
                  title={intl.formatMessage({id: "cont.exclude"}) + this.state.selected.length + intl.formatMessage({id: "cont.contact?"})}
                  desc={intl.formatMessage({id: "cont.youconfirm"}) + this.state.selected.length + intl.formatMessage({id: "cont.contact?"})}
                  buttonAccept={intl.formatMessage({id: "cont.confirm"})}
                  buttonCancel={intl.formatMessage({id: "cont.cancel"})}
                  callback={this.dialogCallback}
                  />
            </div>
        </React.Fragment>
    )
  };
}

function mapStateToProps(state) {
  return {
    all: state.contacts.all,
    delresult: state.contacts.contact
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    allContacts,
    deleteContacts,
    updateContacts,
    searchContacts
  }, dispatch);
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(stylesContacts)(Contacts)));
