import {
    EditorState,
    AtomicBlockUtils
} from 'draft-js';

export default (editorState, title, link, color, extraData) => {
    const urlType = 'CALLTOACTION';
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
        urlType,
        'IMMUTABLE', {
            ...extraData,
            title: title,
            link: link,
            color: color
        }
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
        editorState,
        entityKey,
        ' '
    );

    return EditorState.forceSelection(
        newEditorState,
        newEditorState.getCurrentContent().getSelectionAfter()
    )
};