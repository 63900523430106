// jshint ignore: start
import React, { useState, useRef, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from '@material-ui/core/IconButton';
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import MyButton from '../tools/MyButton'
import ConnectDomain from './connectdomain';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import RotateLoader from 'react-spinners/RotateLoader';
import FormControl from '@material-ui/core/FormControl'
import MyInput from '../tools/MyInput'
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux";
import { requestDomain } from "../state/store/actions/domainsettings";
import { setNotification } from "../state/store/actions/notification";
import { useIntl, navigate } from 'gatsby-plugin-intl';
import * as firebase from 'firebase/app'
import 'firebase/auth'
import SEO from '../components/seo'

const useStyles = makeStyles({
  formroot: {
    width: 200,
  },
  margin: {
    marginRight: 24
  },
  verified: {
    borderRadius: 4,
    backgroundColor: 'rgb(8, 167, 66)',
    padding: '5px 12px',
    color: 'white',
    textAlign: 'center',
    display: 'inline-flex',
    fontSize: '12px'
  },
  not: {
    backgroundColor: '#f50b5a',
  },
  button: {
    border: '1px solid rgb(8, 167, 66)',
    marginRight: 10,
    color: 'rgb(8, 167, 66)',
    padding: 5,
    marginRight: 12
  },
  button2: {
    marginLeft: 20,
    color: 'black',
    padding: 5,
  },
  baseloading: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, .70)'
  },
  override: {
    display: 'block',
    margin: 'auto auto',
    borderColor: 'red',
  },
  ya: {
    padding: '7.5px 12px'
  }
});

export default function DomainsSettings() {
  const [allowsave, setAllowSave] = useState(true)
  const [openNewDomain, setOpenNewDomain] = useState(false)
  const [verified, setVerified] = useState({})
  const [deleted, setDeleted] = useState({})
  const [loading, setLoading] = useState(true)
  const [editFrom, setEditFrom] = useState(false)
  const [editValue, setEditValue] = useState("")
  const [editText, setEditText] = useState("")
  const [mydomain, setMydomain] = useState("");
  const domainsettings = useSelector(state => state.domainsettings);
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();

  const toggleDrawer = (openNewDomain) => event => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setOpenNewDomain(openNewDomain)
    };

  const sendOpenDomain = (valor, close) => {
    if (!valor && close) {
      setLoading(true)
      dispatch(requestDomain())
    }
    setOpenNewDomain(valor)
  }

  function authUser() {
      return new Promise(function (resolve, reject) {
        firebase.auth().onAuthStateChanged(function (user) {
          if (user) {
            resolve(user);
          } else {
            reject('User not logged in');
            var gu = localStorage.getItem("gatsbyUser");
            if (gu) {
              localStorage.setItem("gatsbyUser", JSON.stringify({}));
            }
            navigate("/app/login")
          }
        });
      });
    }

    function usePrevious(value) {
      const ref = useRef();
      useEffect(() => {
        if (!ref.current) {
          ref.current = true;          
          authUser().then((user) => {
            dispatch(requestDomain())
          }, (e) => {
            console.log(e);
          });
        }
        ref.current = value
      }, [value]);
      return ref.current;
    }

    function createData(selected, key, name, email, status, dns, action) {
      return {selected, key, name, email, status, dns, action };
    }

    function Red() {
      return <div className={classes.verified + ' ' + classes.not}>{intl.formatMessage({ id: "dom.unverified" })}</div>
    }
  
    function Green() {
      return <div className={classes.verified}>{intl.formatMessage({ id: "dom.verified" })}</div>
    }

    const handleVerifyDomain = (e) => {
      const { id } = e.currentTarget
      setLoading(true)
      authUser().then((user) => {
        if (user) {
          const query = {
            uid: user.uid,
            email: id,
          }
          firebase.functions().httpsCallable('verifyDomain')({
            query
          }).then(result => {
            if (result.data) {
              if (result.data.code === 400) {
                setVerified(result.data)
              } else if (result.data.code === 401) {
                setVerified(result.data)
              }
            }
            setLoading(false)
          });
        }
      })
    }

    const handleDeleteDomain = (e) => {
      const { id } = e.currentTarget
      setLoading(true)
      authUser().then((user) => {
        if (user) {
          const query = {
            uid: user.uid,
            email: id,
          }
          firebase.functions().httpsCallable('deleteDomain')({
            query
          }).then(result => {
            if (result.data) {
              if (result.data.code === 400) {
                setDeleted(result.data)
              }
            }
          });
        }
      })
    }

    var rows = []
    if (domainsettings.mydomain) {
      domainsettings.mydomain.map(domain => {  
        rows.push(createData(
          domain.selected,
          domain.mykey,
          domain.from ? domain.from : domain.email.replace('@' + domain.domain, ''),
          domain.email,
          <span style={{whiteSpace: 'nowrap'}}>{domain.domain_verified ? <Green/> : 
            <React.Fragment>
              <IconButton className={classes.button} onClick={handleVerifyDomain} id={domain.email} aria-label="refresh">
                <RefreshIcon />
              </IconButton>
              <Red/>
            </React.Fragment>
          }</span>,
          <a>{intl.formatMessage({ id: "dom.settings" })}</a>,
          <span>
          { domain.domain !== "appuro.com" &&
            <Button onClick={handleDeleteDomain} id={domain.email} variant="outlined" color="secondary">{intl.formatMessage({ id: "dom.delete" })}</Button>
          }
          </span>,
        ))
      })
    }

    const handleChangeFrom = (evt) => {
      setEditText(evt.target.value)
    }

    const handleFrom = (evt) => {
      const {id, value} = evt.currentTarget
      if (id === "edit") {
        setEditFrom(true)
        setEditValue(value)
        setEditText("")
      } else {
        if (editText === "") {
          setEditFrom(false)
          setEditValue("")
          return;
        }
        setLoading(true)
         authUser().then((user) => {
           if (user) {
              const query = {
                uid: user.uid,
                editValue: editValue,
                editText: editText
              }
              firebase.functions().httpsCallable('updateFrom')({
                query
              }).then(result => {
                if (result.data) {
                  const {
                    code,
                  } = result.data
                  if (code === 400) {
                    setEditText("")
                    dispatch(requestDomain())
                  } else if (code === 401) {
                    console.log("Error")
                  }
                }
             });
           }
         })
        setEditFrom(false)
        setEditValue("")
      }
    }

    const handleGroup = (evt) => {
      const value = evt.currentTarget.value
      setMydomain(value)
      setLoading(true)
      authUser().then((user) => {
        if (user) {
          const query = {
            uid: user.uid,
            sel: value,
          }
          firebase.functions().httpsCallable('updateSelDomain')({
            query
          }).then(result => {
            if (result.data) {
              const {
                code,
              } = result.data
              if (code === 400) {
                setLoading(false)
              } else if (code === 401) {
                console.log("Error")
              }
            }
          });
        }
      })
    }

    const prevValue = usePrevious({domainsettings, verified, deleted, editFrom});
    useEffect(() => {
        if (prevValue) {
          if (prevValue.verified) {
            if (prevValue.verified !== verified) {
              if (verified.VerificationStatus === "Success") {
                dispatch(requestDomain())
              } else if (verified.VerificationStatus === "Pending") {
                setLoading(false)
                dispatch(setNotification(intl.formatMessage({ id: "dom.trylater" }), true, 'warning'))
            }
          }
          if (prevValue['domainsettings']['mydomain']) {
            if (prevValue['domainsettings']['mydomain'] !== domainsettings['mydomain']) {
              if (verified.VerificationStatus === "Success") {
                setLoading(false)
                dispatch(setNotification(intl.formatMessage({ id: "dom.ready" }), true, 'success'))
              }
            }
          }
          if (prevValue['domainsettings'] !== domainsettings) {
            if (domainsettings.mydomain[0]) {
              setMydomain(domainsettings.mydomain[0].selected)
            }
            setLoading(false)
          }
          if (prevValue.deleted) {
            if (prevValue.deleted !== deleted) {
              dispatch(requestDomain())
            }
          }
          if (prevValue.editFrom !== editFrom) {
            //setLoading(false)
          }
        }
      }
      return function () {
        // CLEANUP
      };
    }, [domainsettings, verified, deleted, editFrom])

  return (
    <div>
      <SEO title={intl.formatMessage({ id: "dom.domsettings" })} />
      <Toolbar >
        <h1 style={{flex: 1}}>{intl.formatMessage({ id: "dom.domsettings" })}</h1>
      </Toolbar>
      <Divider/>
      {/* Company */}
      <div style={{padding: '20px 0 0'}}>
        <Typography style={{marginBottom: 10}} color="inherit" variant="h6" component="p">{intl.formatMessage({ id: "left.domains" })}</Typography>
      </div>
        <RadioGroup aria-label="gender" name="domain1" value={mydomain} onChange={handleGroup}>
        { domainsettings.mydomain &&
          <Table className={classes.table}>
          <colgroup>
              <col width="auto" />
              <col width="auto" />
              <col width="auto" />
              <col width="auto" />
              <col width="auto" />
          </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>{intl.formatMessage({ id: "dom.name" })}</TableCell>
                <TableCell align="left">{intl.formatMessage({ id: "left.email" })}</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.key}>
                  <TableCell component="th" scope="row" style={{whiteSpace: 'nowrap'}}>
                    {
                      (editValue === row.key)
                      ? 
                        <React.Fragment>
                          <FormControl className={classes.formroot}>
                            <MyInput onChange={handleChangeFrom} value={editText}  classes={{input: classes.ya}} id="editfrom" style={{width: '100%'}}/>
                          </FormControl>
                          <Button className={classes.button2} onClick={handleFrom} id="save" value={row.key} variant="outlined" color="secondary"><CheckOutlinedIcon/></Button>
                        </React.Fragment>
                      :
                      <React.Fragment>
                        <FormControlLabel value={row.key} control={<Radio />} label={row.name} />
                        <Button disabled={editFrom} className={classes.button2} onClick={handleFrom} id="edit" value={row.key} variant="outlined" color="secondary"><EditOutlinedIcon/></Button>
                      </React.Fragment>
                    }
                  </TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="right">{row.status}</TableCell>
                  <TableCell align="right">{row.dns}</TableCell>
                  <TableCell align="right">{row.action}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
        </RadioGroup>
        <div style={{padding: '20px 0'}}>
      <MyButton onClick={toggleDrawer(true)} value="">{intl.formatMessage({ id: "dom.verify" })}</MyButton>
      </div>
      { loading &&
        <div className={classes.baseloading}>
          <div style={{paddingLeft: '240px', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <RotateLoader
              css={classes.override}
              sizeUnit={"px"}
              size={15}
              color={'#f5005780'}
              loading={loading}
            />
          </div>
        </div>
      }
      <ConnectDomain intl={intl} open={openNewDomain} sendopendomain={sendOpenDomain}/>
    </div>
  );
}