import { EditorState, AtomicBlockUtils } from 'draft-js';

export default (editorState, url, link, width, extraData) => {
    const urlType = 'IMAGE';
    const contentState = editorState.getCurrentContent();

    const contentStateWithEntity = contentState.createEntity(
        urlType,
        'IMMUTABLE',
        { ...extraData, src: url, link: link, width: '30' }
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
        editorState,
        entityKey,
        ' '
    );

  return EditorState.forceSelection(
      newEditorState,
      newEditorState.getCurrentContent().getSelectionAfter()
  )
};

// "1": {
//     "type": "IMAGE",
//     "mutability": "IMMUTABLE",
//     "data": {
//         "src": "https://storage.googleapis.com/appurocom.appspot.com/user%2Femailuploads%2FGKQcdIHfCYSHc3o3UBFWjqlCTIc2%2FO0kTSwvr57A1QYoIkGi7%2FLp60P%2BG5NzzpXDQHWrk%2FLp60egyzne0W7oic-YU.png?alt=media&token=c9bc36b9-1a78-46a2-8aef-cb7a24e38484",
//         "alignment": "center"
//     }
// },
// "1": {
//     "type": "LINK",
//     "mutability": "MUTABLE",
//     "data": {
//         "url": "http://www.appuro.com"
//     }