import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import PrivateRoute from "../components/privateRoute"
import Contacts from "../components/contacts"
import Account from "../components/account"
import Email from "../components/email"
import Login from "../components/login"
import GeneralSettings from "../components/generalsettings"
import DomainsSettings from "../components/domainssettings"
import EmailSettings from "../components/emailsettings"

const App = () => {
  return (
    <Layout>
        <Router>
          <PrivateRoute path="/app/contacts" component={Contacts} />
          <PrivateRoute path=":locale/app/contacts" component={Contacts} />
          <PrivateRoute path="/app/account" component={Account} />
          <PrivateRoute path=":locale/app/account" component={Account} />
          <PrivateRoute path="/app/email" component={Email} />
          <PrivateRoute path=":locale/app/email" component={Email} />
          <PrivateRoute path="/app/settings/general" component={GeneralSettings} />
          <PrivateRoute path=":locale/app/settings/general" component={GeneralSettings} />
          <PrivateRoute path="/app/settings/domains" component={DomainsSettings} />
          <PrivateRoute path=":locale/app/settings/domains" component={DomainsSettings} />
          <PrivateRoute path="/app/settings/email" component={EmailSettings} />
          <PrivateRoute path=":locale/app/settings/email" component={EmailSettings} />
          <Login path="/app/login" />
          <Login path=":locale/app/login" />
        </Router>
      </Layout>
  )
}
export default App